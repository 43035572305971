<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 11:04:33
 * @LastEditTime: 2021-07-01 13:59:19
 * @LastEditors: Please set LastEditors
 * @Description: 指标卡圆形
 * @FilePath: \user\src\views\businessChart\components\cardCircular.vue
-->
<template>
  <div class="card-circular">
    <div ref="circular" class="circular-echarts">
      <div
        :ref="'echarts'+item.uuid"
        class="circular-echarts-con"
        :id="'echarts'+item.uuid"
      ></div>
    </div>
    <div class="circular-conter">
      <div>
        <p
          class="default-conter-title"
          :style="{'color':item.titleColor,'font-size':item.titleFont+'px'}"
        >{{ item.title }}</p>
        <p
          class="default-conter-text"
          :style="{'color':item.contentColor,'font-size':item.contentFont+'px'}"
          @click.stop="through"
        >{{ valueStr }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardCircular",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      circular() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      circular() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      circular() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      circular() {
        return false;
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: 0, // 取出的真实值
      valueStr: "", // 显示值
      contrast: 0, // 对比值
      zbData: [],
      myChart: null
    };
  },
  mounted() {
    console.log(this.item);
    console.log(this.picData);
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
    } else if (showType === "2") {
      // 百分百
      this.valueStr = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.valueStr = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.valueStr = thousandFormat(Number(this.value).toFixed(decimal));
    }
    setTimeout(() => {
      this.circular();
    }, 500);
  },
  watch: {
    //
    resized() {
      if (this.myChart) {
        // const dom = this.$refs.circular;
        // const dom2 = this.$refs.circular2;
        // const h = dom.offsetHeight - 20;
        // const w = dom.offsetWidth - 20;
        // console.log(dom);
        // if (w * 0.8 > h) {
        //   dom2.style.height = `${h}px`;
        // } else {
        //   dom2.style.height = `${w * 0.8}px`;
        // }
        this.myChart.resize();
      }
    },
    isFullScreen() {

    }
  },
  methods: {
    // 画圈
    circular() {
      const zcolorList = [this.item.yData[0].color];
      // 取出对比值
      if (this.item.yData2 && this.item.yData2.length) {
        zcolorList.push(this.item.yData2[0].color);
        const controlNameVariable2 = this.item.yData2[0].controlNameVariable;
        const controlName2 = this.item.yData2[0].controlName;
        (this.zbData || []).forEach((element) => {
          if (element.id === controlNameVariable2 && element.name === controlName2) {
            this.contrast = element.value;
          }
        });
      }
      // 得到需要展示的值
      let vlist;
      if (String(this.item.yData[0].controlType) === "5") {
        vlist = [{ value: 100 }];
      } else if (this.contrast === 0) {
        vlist = [{ value: 100 }];
      } else {
        // 对比值 存在, 按比例处理
        let bili = this.value / this.contrast;
        if (bili < 0) {
          bili = 0;
          vlist = [{ value: 0 }, { value: 100 }];
        } else if (bili > 1) {
          bili = 1;
          vlist = [{ value: 100 }];
        } else {
          const chazhi = this.contrast - this.value;
          vlist = [
            { value: this.value, name: "" },
            { value: chazhi, name: "" }
          ];
        }
      }
      // const dom = document.getElementById(`echarts${this.item.uuid}`);
      const dom = this.$refs[`echarts${this.item.uuid}`];
      this.myChart = this.$charts.init(dom);
      const option = {
        series: [
          {
            color: zcolorList,
            name: "",
            type: "pie",
            radius: ["80%", "100%"],
            avoidLabelOverlap: false,
            legendHoverLink: false,
            hoverAnimation: false,
            silent: true, // 不影响点击事件
            label: {
              normal: {
                show: false,
                position: "center"
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: vlist
          }
        ]
      };
      this.myChart.setOption(option);
    },
    // 穿透事件
    through() {
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-circular {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .circular-echarts {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .circular-echarts-con {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .circular-conter {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }
  }
}
</style>
