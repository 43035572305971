<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 18:11:55
 * @LastEditTime: 2022-08-08 15:21:36
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 首页全屏 后跳转的页面
 * @FilePath: \user\src\views\
-->
<template>
  <div class="full-screen">
    <img v-if="type == '1'" style="width: 100%;" src="../../assets/images/BalanceSheet.png" />
    <img v-if="type == '2'" style="width: 100%;"  src="../../assets/images/cashSheet.png" />
    <img v-if="type == '3'" style="width: 100%;"  src="../../assets/images/profitSheet.png" />
  </div>
</template>

<script>
import indexFull from "@/mixins/indexFull/indexFull"; // 公共方法

export default {
  name: "sheet",
  // mixins: [toggleFullScreen],
  mixins: [indexFull],
  data() {
    return {
      type: "1"
    };
  },
  mounted() {
    console.log(this);
    const { type } = this.$route?.query || {};
    this.type = type;
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.full-screen{
  height: 100%;
  width: 100%;
  text-align: center;
  overflow-y: auto;
}
</style>
