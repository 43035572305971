var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full-screen" }, [
    _vm.type == "1"
      ? _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("../../assets/images/BalanceSheet.png") },
        })
      : _vm._e(),
    _vm.type == "2"
      ? _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("../../assets/images/cashSheet.png") },
        })
      : _vm._e(),
    _vm.type == "3"
      ? _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("../../assets/images/profitSheet.png") },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }