var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticStyle: { "text-aglin": "left" },
          attrs: {
            visible: _vm.visiable,
            title: "已隐藏看板",
            width: "75%",
            "en-body-scroll": "",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _vm.finish
            ? _c(
                "div",
                { staticClass: "box" },
                _vm._l(_vm.list, function (item, index) {
                  return _c("div", { key: index, staticClass: "temp" }, [
                    _c(
                      "div",
                      { staticClass: "temp-content" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          },
                          [
                            _c("en-card", {
                              staticStyle: { "margin-left": "12px" },
                              attrs: {
                                type: "normal",
                                title: item.name,
                                "icon-name": _vm.typeList[item.type].icon,
                                "icon-bg": _vm.iconColor(index),
                                "icon-color": "#FFFFFF",
                                "sub-title": _vm.typeList[item.type].name,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "en-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visiable = false
                    },
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }