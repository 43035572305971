/*
 * @Author: zhangjiaheng zhangjiaheng@enfry.com
 * @Date: 2022-07-28 14:52:58
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @LastEditTime: 2022-08-04 11:15:30
 * @FilePath: \user\src\mixins\indexFull\indexFull.js
 * @Description: 全屏展示 webscok的公共方法
 */
import SockService from "@/api/sock.js";

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    initWebscok() {
      this.sockService = new SockService(`wss://${this.userInfo.enfryIp}/news/socketServer?channelType=PC&mobileNo=${this.userInfo.mobileNo}`, this.getNewNumber);
    },
    /**
     * @description: 获取新消息数量和待回执数量
     */
    async getNewNumber(evt) {
      const This = this;
      const data = JSON.parse(evt.data);
      console.log(data);
      // 接受同屏信息 调用全屏
      if (data.path) {
        // if (data.tenantId === this.userInfo.tenantId && data.userId !== this.userInfo.userId) {
          if (data.path === "/indexVice") {
            // 用模拟点击的方式进入副页
            // 标记为副页
            window.localStorage.setItem("sameScreenAuthType", "indexVice");
            // this.fireKeyEvent("keydown", 122);
            This.$router.push({ path: data.path });
          } else if (data.path === "-1") {
            if (window.localStorage.getItem("sameScreenAuthType") === "indexVice") {
              this.$router.push({ path: "/indexVice" });
            } else {
              this.$router.push({ path: "/indexFull" });
            }
          } else if (data.path === "2") {
            // 特殊处理
            if (window.location.hostname === "en2.enfry.com")This.$router.push({ path: data.path2 });
            if (window.location.hostname === "en3.enfry.com")This.$router.push({ path: data.path3 });
          } else {
            This.$router.push({ path: data.path });
          }
        // }
      }
    }
  }
};
