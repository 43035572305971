<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-08 13:03:38
 * @LastEditTime: 2022-07-27 14:18:19
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 看板图形
 * @FilePath: \user\src\views\businessChart\components\chart.vue
-->
<template>
  <div class="chart" :class="{ 'scoll-chart': isIndex !== 'index' }">
    <div class="chart-title" v-if="isIndex !== 'index'">
      <!-- 单图全屏状态下不显示 多图全屏显示  -->
      <div v-if="!isFullScreen || fullType === 2" :style="fullType === 2 ? 'color:#fff;' : ''" class="pull-left">
        <span v-if="type === '2' || templateIdList.length === 0" @click.stop="clickTitle(null)">{{ item.name }}</span>
        <el-popover v-else placement="bottom-start" width="200" trigger="hover" popper-class="chart__popover">
          <div v-for="(item1, key) in templateIdList" :key="key" class="tip" @click.stop="clickTitle(item1)">{{ item1.name }}</div>
          <span slot="reference">
            <span>{{ item.name }}</span>
            <en-icon name="zhankai1" :size="14" class="icon"></en-icon>
          </span>
        </el-popover>
      </div>
      <!-- 全屏状态下不显示 -->
      <div v-if="!isFullScreen" class="pull-right">
        <chartTop v-if="item.isTopObj" :isTopObj="item.isTopObj" @close="isTopClose"></chartTop>
        <span title="筛选" v-if="(item.condition || []).length > 0 && isIndex === 'model'">
          <en-icon name="shaixuan" size="small" style="color:#9367EB;margin-left:15px;" @click.native.stop="toShow"> </en-icon>
        </span>
        <span title="隐藏" v-if="isIndex == 'model' && false">
          <en-icon name="yincang-kanban" size="small" style="color:#F76B6B;margin-left:15px;" @click.native.stop="isHide"> </en-icon>
        </span>
        <!-- <span title="全屏" v-if="isIndex == 'model'">
          <en-icon name="quanping" size="small" style="color:#3e90fe;margin-left:15px;" @click.native.stop="isFull"> </en-icon>
        </span> -->
        <en-icon v-if="isIndex === 'index'" name="zuocecaidanlan-shouqi" size="20" style="color: #A9B5C6;cursor: pointer;margin-left: 10px;" @click.native="goDetails"></en-icon>
      </div>
    </div>
    <card-header :title="menuData.mdName + '-' + menuData.name" v-else-if="false">
      <template #right>
        <div>
          <en-icon v-if="edit" class="header-right-arrow" name="shanchu-danchuang" size="12px" @click.native="deleteData"></en-icon>
          <en-icon v-else @click.native="goDetails" class="header-right-arrow" size="12px" name="shouqi"></en-icon>
        </div>
      </template>
    </card-header>
    <en-result v-if="!hasPower" type="NoData" subTitle="当前图表无查看权限，请与管理员联系"> </en-result>
    <en-result v-else-if="!noData" type="NoData"> </en-result>
    <div v-else-if="picType !== '013' && picType !== '019'" class="echarts-box" :class="picType === '013' ? 'box-013' : ''" :ref="'echarts' + item.id"></div>
    <div v-else-if="picType == '019'" class="echarts-box" :class="picType === '013' ? 'box-013' : ''" :ref="'echarts' + item.id">
      <!-- 地图 -->
      <chartMap v-if="type019" :picData="picData" :resized="resized" :isFullScreen="isFullScreen" @through="mapClick"></chartMap>
    </div>
    <div v-else class="echarts-box" :class="picType === '013' ? 'box-013' : ''">
      <grid-layout
        v-if="type013 && picType === '013'"
        :layout.sync="picData.picData.charts"
        :col-num="12"
        :row-height="60"
        :is-draggable="false"
        :is-resizable="false"
        :vertical-compact="false"
        :margin="[10, 10]"
        :use-css-transforms="false"
        class="grid-box"
        style="transition:none;"
      >
        <grid-item v-for="(item, k) in picData.picData.charts" :key="k" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :minW="2" :minH="2" class="grid-item013">
          <!-- 默认指标卡 -->
          <cardDefault v-if="item.type === '1' && type013" :picData="picData" :item="item" :index="k" :resized="resized" :isFullScreen="isFullScreen" @through="cardClick"></cardDefault>
          <!-- 圆形指标卡 -->
          <cardCircular v-if="item.type === '2' && type013" :picData="picData" :item="item" :index="k" :resized="resized" :isFullScreen="isFullScreen" @through="cardClick"></cardCircular>
          <!-- 表格指标卡 -->
          <cardTable v-if="item.type === '3' && type013" :picData="picData" :item="item" :index="k" :resized="resized" :isFullScreen="isFullScreen" @through="cardClick"></cardTable>
          <!-- 线性指标卡 -->
          <cardLine v-if="item.type === '6' && type013" :picData="picData" :item="item" :index="k" :resized="resized" :isFullScreen="isFullScreen" @through="cardClick"></cardLine>
          <!-- 指标卡锚点指标 -->
          <anchorPoint v-if="item.type === '7' && type013" :picData="picData" :item="item" :index="k" :resized="resized" :isFullScreen="isFullScreen"></anchorPoint>
        </grid-item>
      </grid-layout>
    </div>
    <!-- 查询条件 -->
    <boardFilter ref="popBoardFilter" :conditions="item.condition" :variables="variables" @saveFilter="getCon"> </boardFilter>
    <!-- 自定义图形穿透选择 -->
    <div class="business-chart-menu" v-if="type006.if" :style="{ left: type006.x + 'px', top: type006.y + 'px' }" @mouseleave="type006.if = false">
      <ul>
        <li v-for="(item, index) in type006.list" :key="index" @click="clickTitle(item, type006.refConditionData)">{{ item.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";
import VueGridLayout from "vue-grid-layout";
import dayJs from "dayjs";
import { enChart } from "@/api/businessChart";
import CardHeader from "@/views/home/components/card-header";
import anchorPoint from "./anchorPoint";
import cardDefault from "./cardDefault";
import cardCircular from "./cardCircular";
import cardTable from "./cardTable";
import cardLine from "./cardLine";
import boardFilter from "./boardFilter";
import chartTop from "./chartTop";
import chartMap from "./chartMap";

export default {
  name: "Chart",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    menuData: {
      type: [Object, Array, String],
      default() {
        return "";
      }
    },
    // 模板或者分类id
    id: {
      type: String,
      default: ""
    },
    // 模板名称
    name: {
      type: String,
      default: ""
    },
    // 1分类和 2模板区分
    type: {
      type: String,
      default: "1"
    },
    // 大小变化
    resized: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    // 条件监听器
    flag: {
      type: Boolean,
      default: false
    },
    // 全局变量
    variables: {
      type: Array,
      default() {
        return [];
      }
    },
    // 当前分类的数据
    classifyObj: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      default: false
    },
    // 单图全屏 多图全屏 全屏类型单图为1 多图为2
    fullType: {
      type: Number,
      default: 1
    },
    // 首页用的参数
    isIndex: {
      type: String,
      default: "model"
    },
    // 主体视图数据
    viewsList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    anchorPoint,
    boardFilter,
    cardDefault,
    cardCircular,
    cardLine,
    cardTable,
    chartTop,
    CardHeader,
    chartMap
  },
  data() {
    return {
      noData: true,
      hasPower: true,
      myChart: null,
      statusStr: {
        0: "0",
        1: "未开始",
        2: "已完成",
        3: "已延期",
        4: "逾期完成",
        5: "预警中",
        6: "进行中"
      },
      chartColor: [
        "#febd55",
        "#ffd54f",
        "#fee855",
        "#c7e44f",
        "#ace64f",
        "#58cb97",
        "#3fb37f",
        "#40cfac",
        "#69d3db",
        "#51b3f1",
        "#5182e4",
        "#8954d4",
        "#ce62d7",
        "#d42d6c",
        "#d2425d",
        "#e94a58",
        "#ff6243",
        "#fe854e",
        "#fda05e",
        "#ff7048"
      ],
      // 图表类型
      picType: "",
      // 图标完整数据
      picData: {},
      type013: false,
      // 分类时 图形的模板多个要下拉显示
      templateIdList: [],
      loading: false,
      isTopObj: {},
      type013sub: false,
      // 自定义图形穿透
      type006: {
        if: false,
        x: 0,
        y: 0,
        list: []
      },
      // 避免改动 传入值 处理
      classifyObj1: {},
      // 地图 数据请求完成标识
      type019: false
    };
  },
  watch: {
    // 监听 大小变化
    resized() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    // 监听 数据变化
    flag() {
      if (this.hasPower) {
        this.mdQueryTotalPicData();
      }
    }
  },
  mounted() {
    // 如果是 首页 先处理条件
    if (this.isIndex === "index") {
      this.item.condition = [];
      this.item.hasPower = "0";
      this.queryCon();
      this.queryclassifyObj();
    } else if (this.fullType === 2) {
      this.classifyObj1 = this.classifyObj;
      // 延迟加载 避免 全屏的时候 渲染太快没有宽度
      setTimeout(() => {
        this.init();
      }, 500);
    } else {
      this.classifyObj1 = this.classifyObj;
      this.init();
    }
  },
  methods: {
    // 首页需要自己查询 查询条件
    async queryCon() {
      const list = await enChart.mdQueryCondition({
        refType: this.type,
        id: this.id
      });
      (list || []).forEach((element) => {
        if (element.totalPicId.indexOf(this.item.id) > -1) {
          this.item.condition.push(element);
        }
      });
      this.init();
    },
    deleteData() {
      this.$emit("deleteData");
    },
    // 查询 分类
    async queryclassifyObj() {
      const This = this;
      const p = { withTemplate: 0 };
      this.classify = await enChart.mdUserQueryTemplateClassify(p);
      const res = this.classify.filter((b) => b.id === This.id);
      console.log(res);
      if (res.length) {
        this.classifyObj1 = res[0];
      }
    },
    // 初始化
    init() {
      this.hasPower = this.item.hasPower === "0";
      if (this.hasPower) {
        // 处理top项参数 如果已经有了 isTopObj就不需要处理
        if (!this.item.isTopObj) {
          if (this.item.isTop === 0) {
            this.isTopObj = {
              isTop: this.item.isTop, // 0开启 1关闭
              topSort: "001", // 000升序 001降序
              topOption: this.item.topOption // 用户端输入值
              // topNumber: this.item.topNumber// 管理端配置的值
            };
            if (!this.isTopObj.topOption) {
              this.isTopObj.topNumber = this.item.topNumber;
            }
            // 有排序字段的情况
            if (this.item.sortType) {
              try {
                const a = this.item.sortType.split("_")[1];
                if (a === "000" || a === "001") {
                  this.isTopObj.topSort = "000";
                } else {
                  this.isTopObj.topSort = "001";
                }
              } catch (error) {
                this.isTopObj.topSort = "001";
              }
            }
            this.item.isTopObj = this.isTopObj;
          }
        }
        this.mdQueryTotalPicData();
      }
    },
    // 查询详细数据
    async mdQueryTotalPicData() {
      let rspData = {};
      const This = this;
      // 全屏显示不用请求数据
      if (!this.isFullScreen) {
        this.loading = true;
        const conditions = [];
        // 组装查询条件
        (this.item.condition || []).forEach((element) => {
          const p1 = {
            fieldName: element.fieldName,
            fieldNameVariable: element.fieldNameVariable,
            fieldType: element.fieldType,
            value: element.initValue,
            valueText: element.initValueText,
            area: element.area,
            baseDataType: element.baseDataType || "",
            baseDataTableType: element.baseDataTableType || "",
            valueType: element.valueType
          };
          conditions.push(p1);
        });
        const p = {
          // 适配主题
          dataType: this.type === "3" ? "2" : this.type,
          id: this.id,
          picId: this.item.id,
          conditions: JSON.stringify(conditions)
        };
        // 如果有top项
        if (this.item.isTopObj) {
          Object.assign(p, this.item.isTopObj);
        }
        try {
          rspData = ((await enChart.mdQueryTotalPicData(p)) || [])[0];
        } catch (error) {
          //
        }

        this.loading = false;
        // 处理多个模板情况
        try {
          const list = (rspData.templateId || "").split(",");
          // 剔除为空的情况
          const list1 = [];
          list.forEach((element) => {
            if (element) {
              list1.push(element);
            }
          });
          // 清除前面有空值的情况
          rspData.templateId = list1.join(",");
          if (this.type === "1" && list1.length > 1) {
            list1.forEach((element) => {
              const item = (This.classifyObj1.templateList || []).find((n) => n.id === element);
              This.templateIdList.push({ id: element, name: item.name });
            });
          }
          // 删除不是 自己的 筛选条件
          const temlist = rspData.templateId.split(",");
          const conditionData = [];
          rspData.conditionData.forEach((element) => {
            if (element.templateId) {
              if (temlist.indexOf(element.templateId) > -1) {
                conditionData.push(element);
              }
            } else {
              conditionData.push(element);
            }
          });
          rspData.conditionData = conditionData;
          // 剔除没有开启的条件
          const conList = [];
          rspData.picConditionData.forEach((element) => {
            if (String(element.isCondition) === "0") {
              conList.push(element);
            }
          });
          rspData.picConditionData = conList;
          this.item.rspData = rspData;
        } catch (error) {
          console.log(error);
        }
      } else {
        rspData = this.item.rspData;
      }
      if (rspData?.groupData?.length === 0 && rspData.picData.type !== "013") {
        this.$emit("toParent", rspData);
        this.noData = false;
        return;
      }
      // 有数据
      this.noData = true;
      This.picType = rspData.picData.type;
      this.type013 = false;
      this.type013sub = false;
      this.$nextTick(() => {
        // echarts图形
        if (rspData.picData.type !== "013" && rspData.picData.type !== "019") {
          // const dom = document.getElementById(`echarts${this.item.id}`);
          const dom = this.$refs[`echarts${this.item.id}`];
          This.myChart = This.$charts.init(dom);
          const option = This.getOption(rspData);
          if (!This.isFullScreen) {
            This.myChart.off("click");
            This.myChart.on("click", (param) => {
              // 先处理公共参数 图形自带的查询条件 和 查询条件
              const refConditionData = This.makeupCondition(This.item.rspData);
              console.log(refConditionData);
              // 当前点击项
              // 先使用dataIndex来判断;
              let gcon = {};
              gcon = This.item.rspData.groupData[param.dataIndex];
              if (gcon.showName !== param.name) {
                // 如果取出的值不一致，再到groupData里面去找
                const gcon1 = (This.item.rspData.groupData || []).find((n) => n.showName === param.name);
                if (gcon1) {
                  gcon = gcon1;
                }
              }
              if (param.data.groupData) {
                gcon = param.data.groupData;
              }
              // 时间类型的 月份 需要把月份拼接完成
              let field = {};
              // 如果是自定义图 并且是多个模板 需要特殊处理
              if (This.item.rspData.picData.type === "006" && gcon.templateId.split(",").length > 1) {
                // 出现多个选择模板的
                This.type006.list = [];
                gcon.templateId.split(",").forEach((element) => {
                  const item = (This.classifyObj1.templateList || []).find((n) => n.id === element);
                  if (item) {
                    This.type006.list.push(item);
                  }
                });
                const e = param.event.event;
                This.type006.x = e.clientX;
                This.type006.y = e.clientY;
                This.type006.refConditionData = refConditionData;
                This.type006.if = true;
                // 需要自行开发
                // document.getElementsByClassName("chart__popover")[0].setAttribute("style", `left:${This.type006.x}px;top:${This.type006.y}px`);
                return;
              }
              if (This.item.rspData.picData.type === "006" && gcon.groupId === "") {
                // 无维度
                // go(gcon.templateId);
                const re = this.getUrlConfig(gcon.templateId);
                console.log(re);
                // 穿透
                this.toKip(re, refConditionData);
                return;
              }
              if (String(gcon.type) === "7" && gcon.name.length === 7) {
                const sd = dayJs(gcon.name)
                  .startOf("month")
                  .format("YYYY-MM-DD");
                const ed = dayJs(gcon.name)
                  .endOf("month")
                  .format("YYYY-MM-DD");
                field = {
                  fieldName: gcon.groupName || "",
                  fieldNameVariable: gcon.id,
                  fieldType: gcon.type,
                  area: "MAIN",
                  baseDataType: "",
                  baseDataTableType: "",
                  value: `${sd},${ed}`,
                  valueText: gcon.name,
                  valueType: ""
                };
              } else {
                field = {
                  fieldName: gcon.groupName || "",
                  fieldNameVariable: gcon.id,
                  fieldType: gcon.type,
                  area: "MAIN",
                  baseDataType: "",
                  baseDataTableType: "",
                  value: gcon.name,
                  valueText: gcon.showName
                  // valueType: ""
                };
              }
              // 判断 条件是否重复了
              let flg = false;
              refConditionData.forEach((element1, index1) => {
                if (element1.fieldNameVariable === field.fieldNameVariable) {
                  refConditionData[index1] = field;
                  flg = true;
                }
              });
              if (!flg) {
                refConditionData.push(field);
              }
              // 最终条件
              console.log(refConditionData);
              let re = {};
              if (This.type === "1") {
                // 分类
                re = This.getUrlConfig(This.item.rspData.templateId);
              } else {
                re = This.getUrlConfig(This.id);
              }
              console.log(re);
              // 穿透
              This.toKip(re, refConditionData);
            });
          }

          rspData.option = option;
          // 向父级传数据
          this.$emit("toParent", rspData);
          This.myChart.setOption(option);
        } else if (rspData.picData.type === "013") {
          this.$forceUpdate();
          (rspData.picData.charts || []).forEach((element, index) => {
            const obj = element.style;
            obj.x = Number(obj.x);
            obj.y = Number(obj.y);
            obj.w = Number(obj.width);
            obj.h = Number(obj.height);
            this.$set(element, "x", obj.x);
            this.$set(element, "y", obj.y);
            this.$set(element, "w", obj.w);
            this.$set(element, "h", obj.h);
            this.$set(element, "i", index);
          });
          // This.picType = rspData.picData.type;
          This.picData = rspData;
          this.type013 = true;

          this.$nextTick(() => {
            this.type013sub = true;
          });
          this.$emit("toParent", rspData);
        } else if (rspData.picData.type === "019") {
          console.log(11);
          this.$forceUpdate();
          This.picData = rspData;
          This.type019 = true;
          this.$emit("toParent", rspData);
        }
      });
    },
    // 处理数据
    getOption(rspData) {
      const type = rspData.picData.type;
      const groupData = rspData.groupData;
      const xdata = []; // x轴name
      const legendData = []; // y轴name
      const ldata = []; // 有几个y length为几
      const sdata = []; // y所有的数据
      let series = []; // 给echart数据
      const fontSize = rspData.picData.chartStyle.fontSize || 12; // 字体大小
      const This = this;
      const fontColor = this.isFullScreen ? "#FFFFFF" : "#ffffff"; // 字体颜色 全屏的时候颜色为白色
      const slineColor = this.isFullScreen ? ["#FFFFFF"] : ["#FFFFFF"];
      const alineColor = this.isFullScreen ? ["#FFFFFF"] : ["#FFFFFF"];
      const angle = (rspData.picData.chartStyle.angle || "0") * -1 || "0"; // 旋转角度
      const colorArr = rspData.picData.chartStyle.colorArr;
      const yField = rspData.picData.picDataList[0].yField.data;
      const zField = rspData.picData.picDataList[0]?.zField?.data;
      const ydata = [];
      (rspData.picData.picDataList[0].yField.data || []).forEach((element, index) => {
        const name = element.alias || element.name || "";
        legendData.push(name);
        const key = index + 1;
        ldata.push({
          id: `${element.nameVariable}_${key}`,
          name,
          showType: element.showType,
          thousandMark: element.thousandMark,
          decimal: element.decimal,
          isPercent: rspData.picData.isPercent,
          zcFlag: "1"
        });
      });
      if (type === "007") {
        (rspData.picData.picDataList[0].zField.data || []).forEach((element, index) => {
          const name = element.alias || element.name || "";
          legendData.push(name);
          const key = rspData.picData.picDataList[0].zField.data.length + index + 1;
          ldata.push({
            id: `${element.nameVariable}_${key}`,
            name,
            showType: element.showType,
            thousandMark: element.thousandMark,
            decimal: element.decimal,
            isPercent: rspData.picData.isPercent,
            zcFlag: "2"
          });
        });
      }
      // 排行榜 需要排序
      if (type === "011") {
        groupData.sort((b, a) => {
          const arra = Number(a.data[0].value);
          const arrb = Number(b.data[0].value);
          return arrb - arra;
        });
      }
      // 百分比堆积图的数据处理
      // 负数 计算器
      let negative = false;
      if (type === "014" || type === "016") {
        (groupData || []).forEach((element) => {
          let zong = 0;
          (element.data || []).forEach((element1) => {
            if (Number(element1.value) < 0) {
              negative = true;
            }
            zong += Math.abs(Number(element1.value));
          });
          if (zong !== 0) {
            (element.data || []).forEach((element1) => {
              const baifen = ((Number(element1.value) / zong) * 100).toFixed(2) || 0;
              element1.value = baifen;
            });
          }
        });
      }
      // 漏洞图是否有副值
      let secondaryFlg = false;
      const zhouSetData = {};
      let secondary = 0;
      if (type === "006") {
        if (rspData.picData.picDataList[0].zField && rspData.picData.picDataList[0].zField.data.length) {
          secondaryFlg = true;
        }

        // 循环所有的图层 把 yz轴放到 zhouSetData中 用uuid来取
        (rspData.picData.picDataList || []).forEach((b) => {
          zhouSetData[b.id] = b;
        });
      }

      // 分组数据的循环
      groupData.forEach((element) => {
        if (element.type === "10" && element.id.indexOf("progressStatus") > -1) {
          // 进度状态转化
          element.showName = this.statusStr[element.name] || "0";
        }
        const dd = element.data || [];
        if (type !== "006") {
          // 把所有的x name压入数组
          xdata.push(element.showName);
          // 把data内容抽离出来
          (element.data || []).forEach((element2) => {
            sdata.push(element2);
          });
        } else if (element.groupId) {
          // 有维度
          // 第一个值是主值 第二个值是副值
          const setData = zhouSetData[dd[0].layerId].yField.data[0];
          const num = Number(dd[0].value || "0");
          xdata.push(element.showName);
          // 有维度的情况 有2中情况
          // 有副值和没有副值
          if (secondaryFlg) {
            // 有副值
            // 处理格式
            const secondarysetData = zhouSetData[dd[1].layerId].zField.data[0];
            const num1 = Number(dd[1].value || "0");
            secondary += Math.abs(num1);
            ydata.push({
              secondaryType: dd[1].type,
              secondaryName: dd[1].showName,
              secondaryVal: Number(num1),
              secondary: Math.abs(num1),
              secondarysetData, // 副值格式
              setData, // 主值的格式
              value: num,
              name: element.showName,
              type: dd[0].type,
              conditionData: element.conditionData || [],
              templateId: element.templateId,
              groupData: element
            });
          } else {
            ydata.push({
              setData,
              value: num,
              name: element.showName,
              type: dd[0].type,
              conditionData: element.conditionData || [],
              templateId: element.templateId,
              groupData: element
            });
          }
        } else {
          // 无维度  使用字段公式处理数值
          // 获取公式解析结果
          const fObj = this.getFieldFormula(rspData.picData.picDataList, dd[0].layerId);
          if (fObj.formula) {
            // 有公式
            try {
              // 公式转换后的值 类型
              // 公式字段 固定格式显示 2位小数，数值 无千分符
              const setData = {
                showType: 1,
                thousandMark: 1,
                decimal: 2
              };
              let shiftStr = fObj.formula;
              let shiftVal = "";
              let shiftType = "";
              const typeArr = [];
              dd.forEach((element2) => {
                if (fObj.formula.indexOf(element2.uuid) > -1) {
                  if (typeArr.indexOf(element2.type) < 0) {
                    typeArr.push(element2.type);
                  }
                  shiftStr = shiftStr.replace(element2.uuid, element2.value);
                }
              });
              // 计算结果
              shiftVal = This.eval(shiftStr);
              if (typeArr.length === 1) {
                shiftType = typeArr[0];
              } else {
                shiftType === "3";
              }
              if (shiftVal > 0) {
                xdata.push(fObj.showName);
                ydata.push({
                  value: shiftVal,
                  name: fObj.showName,
                  type: shiftType,
                  conditionData: element.conditionData || [],
                  layerId: dd[0].layerId,
                  templateId: element.templateId,
                  groupData: element,
                  showFormat: rspData.picData.showFormat, // 数值格式 百分比或者数值
                  setData
                });
              }
            } catch (error) {
              this.noData = false;
              console.log(error);
            }
          } else {
            const setData = zhouSetData[dd[0].layerId].yField.data[0];
            xdata.push(dd[0].name);
            ydata.push({
              value: dd[0].value,
              name: dd[0].name,
              type: dd[0].type,
              conditionData: element.conditionData || [],
              layerId: dd[0].layerId,
              templateId: element.templateId,
              groupData: element,
              showFormat: rspData.picData.showFormat, // 数值格式 百分比或者数值
              setData
            });
          }
        }
      });
      // 循环 y类型数据
      if (type !== "006") {
        const length = ldata.length;
        (ldata || []).forEach((element, index) => {
          const dd = [];
          // 直接用groupData来循环输出数据
          if (["010", "014", "015", "016"].indexOf(type) > -2) {
            // 堆积图 是把groupData里面的数据安装顺序取出组成一组
            (groupData || []).forEach((element2, index2) => {
              let num = 0;
              if (type === "014" || type === "016") {
                // 百分比
                num = Number(element2.data[index].value || "0");
              } else if (element.showType === "2") {
                // 百分比类型
                num = Number(element2.data[index].value || "0") * 100;
              } else {
                // 数值类型
                num = Number(element2.data[index].value || "0");
              }
              dd.push({
                value: num,
                setData: element,
                groupData: element2,
                name: xdata[index2]
              });
            });
          } else {
            // id相同压入数组 组成一组数据
            (sdata || []).forEach((element2, index2) => {
              if (element2.id === element.id) {
                let num = 0;
                if (String(element.showType) === "2") {
                  // 百分比类型
                  num = Number(element2.value || "0") * 100;
                } else {
                  // 数值类型
                  num = Number(element2.value || "0");
                }
                // 饼图 玫瑰 环状 矩形
                if (["004", "008", "009", "012"].indexOf(type) > -1) {
                  dd.push({
                    value: num,
                    name: xdata[index2],
                    setData: element
                  });
                } else {
                  dd.push({ value: num, setData: element, groupData: element2 });
                }
              }
            });
          }
          const obj = This.series(type, element, rspData.picData.isShow, fontSize, rspData.picData);
          obj.data = dd;
          // 双轴图 处理 主轴 次轴的样式
          if (type === "007") {
            const zz = rspData.picData.picDataList[0].zz === "1" ? "line" : "bar";
            const cz = rspData.picData.picDataList[0].cz === "1" ? "line" : "bar";
            obj.type = String(element.zcFlag) === "1" ? zz : cz;
            obj.yAxisIndex = String(element.zcFlag) === "1" ? 0 : 1;
          }
          if (["010", "014", "015", "016"].indexOf(type) > -1) {
            let barBorderRadius = [0, 0, 0, 0];
            // 竖向
            if (type === "010" || type === "014") {
              if (index === 0) {
                barBorderRadius = [0, 0, 5, 5];
              }
              if (index === length - 1) {
                barBorderRadius = [5, 5, 0, 0];
              }
            }
            // 横向
            if (type === "015" || type === "016") {
              if (index === 0) {
                barBorderRadius = [5, 0, 0, 5];
              }
              if (index === length - 1) {
                barBorderRadius = [0, 5, 5, 0];
              }
            }
            obj.itemStyle.normal.barBorderRadius = barBorderRadius;
          }
          series.push(obj);
        });
      } else {
        // 自定义图形
        const arr = [];
        const expressRule = typeof rspData.picData.expressRule === "string" ? JSON.parse(rspData.picData.expressRule) : rspData.picData.expressRule;
        (ydata || []).forEach((element) => {
          arr.push(element.value);
        });
        const maxNum = Math.max.apply(null, arr);
        // 使用图表计算公式处理数据
        ydata.forEach((itm, idx) => {
          let formula = this.getChartFormula(expressRule);
          let showName;
          let showFormat;
          if (expressRule[0]) {
            showName = expressRule[0].showName || "";
            showFormat = expressRule[0].showFormat || "";
          } else {
            showName = "";
            showFormat = "";
          }
          if (formula) {
            try {
              if (formula.indexOf("cur") > -1) {
                formula = formula.replace("cur", itm.value);
              }
              if (formula.indexOf("prev") > -1) {
                const val1 = ydata[idx - 1] ? ydata[idx - 1].value : "";
                formula = formula.replace("prev", val1);
              }
              if (formula.indexOf("next") > -1) {
                const val2 = ydata[idx + 1] ? ydata[idx + 1].value : "";
                formula = formula.replace("next", val2);
              }
              itm.leftvalue = this.eval(formula);
              itm.showName = showName;
              itm.showType = showFormat;
            } catch (error) {
              itm.leftvalue = "";
              itm.showName = showName;
              itm.showType = showFormat;
            }
          } else {
            itm.leftvalue = "";
            itm.showName = showName;
            itm.showType = showFormat;
          }
        });

        // 没有副值 如果所有的副值加起来等于0 那就是默认没有负值 等分
        if (!secondaryFlg) {
          // 中间和右边的文字
          series = [
            {
              name: rspData.picData.name,
              type: "funnel",
              left: "10%",
              top: 10,
              bottom: 30,
              width: "80%",
              min: 0,
              max: maxNum,
              minSize: "0%",
              maxSize: "100%",
              gap: 0,
              sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
              label: {
                normal: {
                  formatter(dd) {
                    const data = dd.data || {};
                    if (String(data.setData.showType) === "2") {
                      // 百分百
                      return `${(Number(data.value || 0) * 100).toFixed(Number(data.setData.decimal || 0))}%`;
                    }
                    if (String(data.setData.thousandMark) === "1") {
                      // 数值
                      return Number(data.value || 0).toFixed(Number(data.setData.decimal || 0));
                    }
                    // 千分符
                    return thousandFormat(Number(data.value || 0).toFixed(Number(data.setData.decimal || 0)));
                  },
                  position: "inside"
                }
              },
              itemStyle: {
                borderWidth: 0
              },
              data: ydata
            },
            {
              name: rspData.picData.name,
              type: "funnel",
              left: "10%",
              top: 10,
              bottom: 30,
              width: "80%",
              min: 0,
              max: maxNum,
              minSize: "0%",
              maxSize: "100%",
              gap: 0,
              sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
              label: {
                normal: {
                  formatter: "{b}",
                  position: "right"
                }
              },
              itemStyle: {
                borderWidth: 0
              },
              data: ydata
            }
          ];
          if (expressRule.length) {
            // 配置了图标公式 才展示
            series.push({
              name: rspData.picData.name,
              type: "funnel",
              left: "10%",
              top: 10,
              bottom: 30,
              width: "80%",
              min: 0,
              max: maxNum,
              minSize: "0%",
              maxSize: "100%",
              sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
              gap: 0,
              label: {
                normal: {
                  formatter(dd) {
                    const data = dd.data || {};
                    if (data.leftvalue) {
                      if (String(data.showType) === "1") {
                        return `${data.showName}: ${Number((data.leftvalue || 0) * 100).toFixed(2)}%`;
                      }
                      return `${data.showName}: ${Number(data.leftvalue || 0).toFixed(2)}`;
                    }
                    return "";
                  },
                  position: "left"
                }
              },
              itemStyle: {
                borderWidth: 0
              },
              data: ydata
            });
          }
        } else {
          // 有副值
          series = [];
          const H = [];
          const domstr = `echarts${rspData.picData.id}`;
          // 4.9.9 取出高度 容器高度 减去 top bottom 避免文字看不到 再上下减15
          const height = this.$refs[domstr].offsetHeight - 10 - 30 - 30;
          (ydata || []).forEach((v, k) => {
            let h = 0;
            let top = 0;
            if (secondary === 0) {
              // 如果副值总和等于0
              // 则是平均分是 高度groupData的格数分之一
              h = (1 / groupData.length) * height;
            } else {
              h = (v.secondary / secondary) * height;
            }
            const y = [];
            y.push(v);
            // 记录h 用于取上一级h
            if (k === 0) {
              top = 15;
            } else {
              top = H[k - 1];
            }
            H.push(h + top);
            const itemCen = {
              name: rspData.picData.name,
              type: "funnel",
              left: "10%",
              top,
              height: h,
              width: "80%",
              min: 0,
              max: maxNum,
              minSize: "0%",
              maxSize: "100%",
              gap: 0,
              sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
              label: {
                normal: {
                  formatter(dd) {
                    const data = dd.data || {};
                    let str = "";
                    if (String(data.setData.showType) === "2") {
                      // 百分百
                      str = `${(Number(data.value || 0) * 100).toFixed(Number(data.setData.decimal || 0))}%`;
                    } else if (data.setData.thousandMark === "1") {
                      // 数值
                      str = Number(data.value || 0).toFixed(Number(data.setData.decimal || 0));
                    } else {
                      // 千分符
                      str = thousandFormat(Number(data.value || 0).toFixed(Number(data.setData.decimal || 0)));
                    }
                    // 如果有 副值 则拼接 secondarysetData
                    if (secondaryFlg) {
                      if (String(data.secondarysetData.showType) === "2") {
                        // 百分百
                        str = `${str}&${(Number(data.secondaryVal || 0) * 100).toFixed(Number(data.secondarysetData.decimal || 0))}%`;
                      } else if (data.secondarysetData.thousandMark === "1") {
                        // 数值
                        str = `${str}&${Number(data.secondaryVal || 0).toFixed(Number(data.secondarysetData.decimal || 0))}`;
                      } else {
                        // 千分符
                        str = `${str}&${thousandFormat(Number(data.secondaryVal || 0).toFixed(Number(data.secondarysetData.decimal || 0)))}`;
                      }
                    }
                    return str;
                  },
                  position: "inside"
                }
              },

              itemStyle: {
                borderWidth: 0,
                color: colorArr[k % colorArr.length]
              },
              data: y
            };
            const itemRight = {
              name: rspData.picData.name,
              type: "funnel",
              left: "10%",
              top,
              height: h,
              width: "80%",
              min: 0,
              max: maxNum,
              minSize: "0%",
              maxSize: "100%",
              gap: 0,
              sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
              label: {
                normal: {
                  formatter: "{b}",
                  position: "right"
                }
              },
              // 线长
              labelLine: {
                length: 100
              },
              itemStyle: {
                borderWidth: 0,
                color: colorArr[k % colorArr.length]
              },
              data: y
            };
            series.push(itemCen);
            series.push(itemRight);
            if (expressRule.length) {
              const itemLeft = {
                name: rspData.picData.name,
                type: "funnel",
                left: "10%",
                top,
                height: h,
                width: "80%",
                min: 0,
                max: maxNum,
                minSize: "0%",
                maxSize: "100%",
                sort: String(rspData.picData.showControl) === "2" ? "descending" : "none",
                gap: 0,
                label: {
                  normal: {
                    formatter(dd) {
                      const data = dd.data || {};
                      if (data.leftvalue) {
                        if (String(data.showType) === "1") {
                          return `${data.showName}: ${Number((data.leftvalue || 0) * 100).toFixed(2)}%`;
                        }
                        return `${data.showName}: ${Number(data.leftvalue || 0).toFixed(2)}`;
                      }
                      return "";
                    },
                    position: "left"
                  }
                },
                itemStyle: {
                  borderWidth: 0,
                  color: colorArr[k % colorArr.length]
                },
                // 线长
                labelLine: {
                  length: 100
                },
                data: y
              };
              series.push(itemLeft);
            }
          });
        }
      }
      // 组合样式参数
      const styleObj = {
        fontSize, // 字体大小
        colorArr, // 颜色
        fontColor, // 字体颜色
        angle, // 旋转
        slineColor, // 轴颜色
        alineColor, // 轴颜色
        negative // 有无负数 false 无 true有
      };
      const option = This.setOption(type, series, legendData, xdata, groupData, yField, zField, styleObj, secondaryFlg);
      return option;
    },
    series(type, element, isShow, fontSize, picData) {
      let obj = {};
      isShow = String(isShow) !== "1";
      let formatter;
      if (["004", "008", "009"].indexOf(type) > -1) {
        formatter = (dd) => {
          const str = "";
          if (String(picData.isShowDimension) === "0") {
            // 显示维度
            if (String(picData.isShow) === "0" && String(picData.isPercent) === "0") {
              // 都显示
              if (String(element.showType) === "2") {
                // 百分比
                return `${dd.name}：${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}% (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
              }
              if (String(element.thousandMark) === "1") {
                // 数值
                return `${dd.name}：${Number(dd.value || 0).toFixed(Number(element.decimal || 0))} (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
              }
              return `${dd.name}：${thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)))} (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
            }
            if (String(picData.isShow) === "1" && String(picData.isPercent) === "1") {
              // 都不显示
              return dd.name;
            }
            if (String(picData.isShow) === "0" && String(picData.isPercent) === "1") {
              // 显示数值
              if (String(element.showType) === "2") {
                // 百分比
                return `${dd.name}：${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}%`;
              }
              if (String(element.thousandMark) === "1") {
                // 数值
                return `${dd.name}：${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}`;
              }
              return `${dd.name}：${thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)))}`;
            }
            if (String(picData.isShow) === "1" && String(picData.isPercent) === "0") {
              // 显示百分比
              return `${dd.name}： (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
            }
          } else {
            if (String(picData.isShow) === "0" && String(picData.isPercent) === "0") {
              // 都显示
              if (String(element.showType) === "2") {
                // 百分比
                return `${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}% (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
              }
              if (String(element.thousandMark) === "1") {
                // 数值
                return `${Number(dd.value || 0).toFixed(Number(element.decimal || 0))} (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
              }
              return `${thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)))} (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
            }
            if (String(picData.isShow) === "1" && String(picData.isPercent) === "1") {
              // 都不显示
              return "";
            }
            if (String(picData.isShow) === "0" && String(picData.isPercent) === "1") {
              // 显示数值
              if (String(element.showType) === "2") {
                // 百分比
                return `${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}%`;
              }
              if (String(element.thousandMark) === "1") {
                // 数值
                return Number(dd.value || 0).toFixed(Number(element.decimal || 0));
              }
              return thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)));
            }
            if (String(picData.isShow) === "1" && String(picData.isPercent) === "0") {
              // 显示百分比
              return ` (${(dd.percent || 0).toFixed(Number(element.decimal || 0))}%)`;
            }
          }
          return str;
        };
      } else if (["014", "016"].indexOf(type) > -1) {
        // 百分百
        formatter = (dd) => `${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}%`;
      } else {
        formatter = (dd) => {
          // 字段显示设置
          if (String(element.showType) === "2") {
            // 百分百
            return `${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}%`;
          }
          if (String(element.thousandMark) === "1") {
            // 数值
            return Number(dd.value || 0).toFixed(Number(element.decimal || 0));
          }
          // 千分符
          return thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)));
        };
      }
      switch (type) {
        case "001":
          // 柱状图
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 28,
            barMinHeight: 5,
            label: {
              normal: {
                show: isShow,
                position: "top",
                formatter
              }
            },
            data: []
          };
          break;
        case "002":
          // 横状图
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 28,
            barMinHeight: 5,
            label: {
              normal: {
                show: isShow,
                position: "right",
                formatter
              }
            },
            data: []
          };
          break;
        case "003":
          // 折线图
          obj = {
            name: element.name,
            type: "line",
            barMaxWidth: 28,
            label: {
              normal: {
                show: isShow,
                position: "top",
                formatter
              }
            },
            data: []
          };
          break;
        case "004":
          // 饼图
          obj = {
            name: element.name,
            type: "pie",
            label: {
              normal: {
                formatter,
                textStyle: {
                  fontSize
                },
                show: !(picData.isShow === "1" && picData.isPercent === "1" && picData.isShowDimension === "1")
              }
            },
            radius: [0, "58%"],
            center: ["50%", "46%"],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          };
          break;
        case "006":
          // 不做处理
          break;
        case "007":
          // 双轴图
          obj = {
            name: element.name,
            barMaxWidth: 28,
            label: {
              normal: {
                show: isShow,
                position: "top",

                formatter
              }
            },
            data: []
          };
          break;
        case "008":
          // 玫瑰图
          obj = {
            name: element.name,
            type: "pie",
            label: {
              normal: {
                formatter,
                textStyle: {
                  fontSize
                }
              }
            },
            radius: ["30%", "58%"],
            roseType: "radius",
            data: []
          };
          break;
        case "009":
          // 环状图
          obj = {
            name: element.name,
            type: "pie",
            label: {
              normal: {
                formatter,
                textStyle: {
                  fontSize
                }
              }
            },
            radius: ["40%", "58%"],
            data: []
          };
          break;
        case "010":
          // 堆积图
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 18,
            barMinHeight: 5,
            stack: "总量",
            itemStyle: {
              normal: {
                // barBorderRadius
              }
            },
            label: {
              normal: {
                show: isShow,
                position: "inside",
                formatter
              }
            },
            data: []
          };
          break;
        case "011":
          // 排行榜
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 28,
            label: {
              normal: {
                show: isShow,
                color: ["#aeaeae"],
                position: "right",
                formatter
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: [0, 5, 5, 0]
              }
            },
            data: []
          };
          break;
        case "012":
          // 矩形图 面积图
          obj = {
            name: element.name,
            type: "treemap",
            roam: false,
            nodeClick: false,
            breadcrumb: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  formatter(dd) {
                    if (!isShow) {
                      return dd.name;
                    }
                    if (String(element.showType) === "2") {
                      // 百分百
                      return `${dd.name}:${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}%`;
                    }
                    if (String(element.thousandMark) === "1") {
                      // 数值
                      return `${dd.name}:${Number(dd.value || 0).toFixed(Number(element.decimal || 0))}`;
                    }
                    // 千分符

                    return `${dd.name}:${thousandFormat(Number(dd.value || 0).toFixed(Number(element.decimal || 0)))}`;
                  }
                },
                borderWidth: 0
              },
              emphasis: {
                label: {
                  show: true
                }
              }
            },
            data: []
          };
          break;
        case "014":
          // 堆积 百分比
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 18,
            barMinHeight: 5,
            stack: "总量",
            itemStyle: {
              normal: {
                // barBorderRadius
              }
            },
            label: {
              normal: {
                show: isShow,
                position: "inside",
                formatter
              }
            },
            data: []
          };
          break;
        case "015":
          // 堆积条形图
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 18,
            barMinHeight: 5,
            stack: "总量",
            itemStyle: {
              normal: {
                // barBorderRadius
              }
            },
            label: {
              normal: {
                show: isShow,
                position: "inside",
                formatter
              }
            },
            data: []
          };
          break;
        case "016":
          // 堆积条形 百分比图
          obj = {
            name: element.name,
            type: "bar",
            barMaxWidth: 18,
            barMinHeight: 5,
            stack: "总量",
            itemStyle: {
              normal: {
                // barBorderRadius
              }
            },
            label: {
              normal: {
                show: isShow,
                position: "inside",
                formatter
              }
            },
            data: []
          };
          break;
      }
      return obj;
    },
    setOption(type, series, legendData, xdata, groupData, yField, zField, styleObj, secondaryFlg) {
      let option;
      const This = this;
      const formatterName = (name) => (name.length > 4 ? `${name.slice(0, 4)}...` : name);
      // 图例配置
      const legend = {
        type: "scroll",
        pageIconSize: 10,
        pageIconColor: "#179eda",
        pageIconInactiveColor: "#aeaeae",
        pageTextStyle: {
          color: "#aeaeae"
        },
        icon: "circle",
        itemWidth: 6,
        itemHeight: 6,
        itemGap: 6,
        borderRadius: 6,
        bottom: 0,
        textStyle: {
          color: styleObj.fontColor || "#999"
        },
        data: ["004", "008", "009", "006"].indexOf(type) > -1 ? xdata : legendData,
        formatter: formatterName
      };
      // 图表大小配置
      const grid = {
        left: "5%",
        right: "5%",
        top: "15%",
        bottom: "15%",
        containLabel: true
      };
      let formatter;
      if (["004", "008", "009"].indexOf(type) > -1) {
        formatter = (dd) => {
          const res = [];
          res.push(dd.name);
          const f = This.getPicField(groupData, dd.seriesName);
          const setData = dd.data.setData || This.getPicFieldsetData(yField || [], f);
          if (String(setData.showType) === "2") {
            // 百分比
            res.push(`${dd.seriesName} : ${Number(dd.value || 0).toFixed(Number(setData.decimal || 0))}% (${(dd.percent || 0).toFixed(Number(setData.decimal || 0))}%)`);
          } else if (String(setData.thousandMark) === "1") {
            // 数值
            res.push(`${dd.seriesName} : ${Number(dd.value || 0).toFixed(Number(setData.decimal || 0))} (${(dd.percent || 0).toFixed(Number(setData.decimal || 0))}%)`);
          } else {
            res.push(`${dd.seriesName} : ${thousandFormat(Number(dd.value || 0).toFixed(Number(setData.decimal || 0)))} (${(dd.percent || 0).toFixed(Number(setData.decimal || 0))}%)`);
          }
          return res.join("<br/>");
        };
      } else if (type === "012") {
        formatter = (dd) => {
          const res = [];
          res.push(dd.name);
          const f = This.getPicField(groupData, dd.seriesName);
          const setData = dd.data.setData || This.getPicFieldsetData(yField || [], f);
          if (String(setData.showType) === "2") {
            // 百分比
            res.push(`${dd.seriesName} : ${Number(dd.value || 0).toFixed(Number(setData.decimal || 0))}%`);
          } else if (String(setData.thousandMark) === "1") {
            // 数值
            res.push(`${dd.seriesName} : ${Number(dd.value || 0).toFixed(Number(setData.decimal || 0))}`);
          } else {
            res.push(`${dd.seriesName} : ${thousandFormat(Number(dd.value || 0).toFixed(Number(setData.decimal || 0)))}`);
          }
          return res.join("<br/>");
        };
      } else if (["014", "016"].indexOf(type) > -1) {
        formatter = (dd) => {
          const res = [];
          res.push(dd[0].name);
          (dd || []).forEach((item) => {
            const f = This.getPicField(groupData, item.seriesName);
            const setData = item.data.setData || This.getPicFieldsetData(yField || [], f);
            res.push(`${item.seriesName} : ${Number(item.value || 0).toFixed(Number(setData.decimal || 0))}%`);
          });
          return res.join("<br/>");
        };
      } else {
        formatter = (dd) => {
          const res = [];
          res.push(dd[0].name);
          (dd || []).forEach((item) => {
            const f = This.getPicField(groupData, item.seriesName);
            const setData = item.data.setData || This.getPicFieldsetData(yField || [], f) || This.getPicFieldsetData(zField || [], f);
            if (String(setData.showType) === "2") {
              // 百分比
              res.push(`${item.seriesName} : ${Number(item.value || 0).toFixed(Number(setData.decimal || 0))}%`);
            } else if (String(setData.thousandMark) === "1") {
              // 数值
              res.push(`${item.seriesName} : ${Number(item.value || 0).toFixed(Number(setData.decimal || 0))}`);
            } else {
              res.push(`${item.seriesName} : ${thousandFormat(Number(item.value || 0).toFixed(Number(setData.decimal || 0)))}`);
            }
          });
          return res.join("<br/>");
        };
      }
      // 触发项
      const tooltip = {
        showDelay: 0,
        trigger: ["004", "008", "009", "012"].indexOf(type) > -1 ? "item" : "axis",
        textStyle: {
          fontSize: styleObj.fontSize
        },
        formatter
      };
      let axisLabel;
      if (styleObj.negative) {
        // 有负数
        axisLabel = (value) => {
          const texts = [];
          if (value <= -100) {
            texts.push("-100%");
          } else if (value <= -80) {
            texts.push("-80%");
          } else if (value <= -60) {
            texts.push("-60%");
          } else if (value <= -40) {
            texts.push("-40%");
          } else if (value <= -20) {
            texts.push("-20%");
          } else if (value === 0) {
            texts.push("0");
          } else if (value <= 20) {
            texts.push("20%");
          } else if (value <= 40) {
            texts.push("40%");
          } else if (value <= 60) {
            texts.push("60%");
          } else if (value <= 80) {
            texts.push("80%");
          } else {
            texts.push("100%");
          }
          return texts;
        };
      } else {
        axisLabel = (value) => {
          const texts = [];
          if (value === 0) {
            texts.push("0");
          } else if (value <= 20) {
            texts.push("20%");
          } else if (value <= 40) {
            texts.push("40%");
          } else if (value <= 60) {
            texts.push("60%");
          } else if (value <= 80) {
            texts.push("80%");
          } else {
            texts.push("100%");
          }
          return texts;
        };
      }
      switch (type) {
        case "001":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            legend,
            grid,
            xAxis: [
              {
                type: "category",
                axisLabel: {
                  color: styleObj.fontColor,
                  rotate: styleObj.angle,
                  formatter: formatterName
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                data: xdata
              }
            ],
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  color: styleObj.fontColor
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                }
              }
            ],
            series
          };
          break;
        case "002":
          option = {
            tooltip,
            color: styleObj.colorArr,
            legend,
            grid,
            xAxis: {
              axisLabel: {
                color: styleObj.fontColor
              },
              splitLine: {
                lineStyle: {
                  color: styleObj.slineColor
                }
              },
              axisLine: {
                lineStyle: {
                  color: styleObj.alineColor
                }
              },
              type: "value"
            },
            yAxis: {
              axisLabel: {
                color: styleObj.fontColor,
                rotate: styleObj.angle,
                formatter: formatterName
              },
              type: "category",
              splitLine: {
                lineStyle: {
                  color: styleObj.slineColor
                }
              },
              axisLine: {
                lineStyle: {
                  color: styleObj.alineColor
                }
              },
              data: xdata
            },
            series
          };
          break;
        case "003":
          option = {
            tooltip,
            color: styleObj.colorArr,
            legend,
            grid,
            xAxis: {
              type: "category",
              axisLabel: {
                color: styleObj.fontColor,
                rotate: styleObj.angle,
                formatter: formatterName
              },
              boundaryGap: true,
              splitLine: {
                lineStyle: {
                  color: styleObj.slineColor
                }
              },
              axisLine: {
                lineStyle: {
                  color: styleObj.alineColor
                }
              },
              data: xdata
            },
            yAxis: {
              axisLabel: {
                color: styleObj.fontColor
              },
              splitLine: {
                lineStyle: {
                  color: styleObj.slineColor
                }
              },
              axisLine: {
                lineStyle: {
                  color: styleObj.alineColor
                }
              },
              type: "value"
            },
            series
          };
          break;
        case "004":
          option = {
            tooltip,
            grid,
            color: styleObj.colorArr,
            legend,
            series
          };
          break;
        case "006":
          option = {
            tooltip: {
              trigger: "item",
              formatter(dd) {
                const data = dd.data || {};
                const res = [];
                let str = "";
                if (String(data.setData.showType) === "2") {
                  // 百分百
                  str = `${dd.name} : ${(Number(data.value || 0) * 100).toFixed(Number(data.setData.decimal || 0))}%`;
                } else if (String(data.setData.thousandMark) === "1") {
                  // 数值
                  str = `${dd.name} : ${Number(data.value || 0).toFixed(Number(data.setData.decimal || 0))}`;
                } else {
                  // 千分符
                  str = `${dd.name} : ${thousandFormat(Number(data.value || 0).toFixed(Number(data.setData.decimal || 0)))}`;
                }
                // 如果有 副值 则拼接
                if (secondaryFlg) {
                  if (String(data.secondarysetData.showType) === "2") {
                    str = `${str}&${(Number(data.secondaryVal || 0) * 100).toFixed(Number(data.secondarysetData.decimal || 0))}%`;
                  } else if (String(data.secondarysetData.thousandMark) === "1") {
                    // 数值
                    str = `${str}&${Number(data.secondaryVal || 0).toFixed(Number(data.secondarysetData.decimal || 0))}`;
                  } else {
                    // 千分符
                    str = `${str}&${thousandFormat(Number(data.secondaryVal || 0).toFixed(Number(data.secondarysetData.decimal || 0)))}`;
                  }
                }
                res.push(str);
                if (data.leftvalue) {
                  if (String(data.showType) === "1") {
                    res.push(`${data.showName} : ${Number((data.leftvalue || 0) * 100).toFixed(2)}%`);
                  } else if (String(data.showType) === "2") {
                    res.push(`${data.showName} : ${Number(data.leftvalue || 0).toFixed(2)}`);
                  }
                }
                return res.join("<br/>");
              },
              textStyle: {
                fontSize: styleObj.fontSize
              }
            },
            color: styleObj.colorArr,
            legend,
            grid,
            series
          };
          break;
        case "007":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            legend,
            grid,
            xAxis: [
              {
                type: "category",
                axisLabel: {
                  color: styleObj.fontColor,
                  rotate: styleObj.angle,
                  formatter: formatterName
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                data: xdata
              }
            ],
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  color: styleObj.fontColor
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                }
              },
              {
                type: "value",
                axisLabel: {
                  color: styleObj.fontColor
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                }
              }
            ],
            series
          };
          break;
        case "008":
          option = {
            tooltip,
            grid,
            color: styleObj.colorArr,
            legend,
            series
          };
          break;
        case "009":
          option = {
            tooltip,
            grid,
            color: styleObj.colorArr,
            legend,
            series
          };
          break;
        case "010":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            grid,
            legend,
            xAxis: [
              {
                type: "category",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                axisLabel: {
                  interval: 0,
                  rotate: styleObj.angle,
                  color: styleObj.fontColor,
                  formatter: formatterName
                },
                data: xdata
              }
            ],
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  color: styleObj.fontColor
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                }
              }
            ],
            series
          };
          break;
        case "011":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            grid: {
              top: "2%",
              left: "120",
              right: "2%",
              bottom: "2%",
              containLabel: true
            },
            xAxis: [
              {
                type: "value",
                splitLine: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                max(value) {
                  const m = value.max;
                  return m + m * 0.2;
                }
              }
            ],
            yAxis: [
              {
                type: "category",
                splitLine: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  color: styleObj.fontColor,
                  rotate: styleObj.angle,
                  backgroundColor: "#72ade9",
                  margin: 0,
                  borderRadius: [5, 0, 0, 5],
                  formatter(value) {
                    return value.length > 4 ? `{value|${value.slice(0, 4)}...}` : `{value|${value}}`;
                  },
                  showMaxLabel: true,
                  rich: {
                    value: {
                      width: 100,
                      backgroundColor: "#72ade9",
                      padding: [8, 10, 8, 40],
                      borderRadius: [5, 0, 0, 5],
                      align: "left"
                    }
                  }
                },
                data: xdata
              }
            ],
            series
          };
          break;
        case "012":
          option = {
            tooltip,
            color: styleObj.colorArr,
            calculable: false,
            legend,
            series
          };
          break;
        case "014":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            grid,
            legend,
            xAxis: [
              {
                type: "category",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                axisLabel: {
                  interval: 0,
                  rotate: styleObj.angle,
                  color: styleObj.fontColor,
                  formatter: formatterName
                },
                data: xdata
              }
            ],
            yAxis: [
              {
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                formatter: "{c}%",
                max: 100,
                min: styleObj.negative ? -100 : 0,
                axisLabel: {
                  color: styleObj.fontColor,
                  formatter: axisLabel
                }
              }
            ],
            series
          };
          break;
        case "015":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            grid,
            legend,
            yAxis: [
              {
                type: "category",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                axisLabel: {
                  interval: 0,
                  rotate: styleObj.angle,
                  color: styleObj.fontColor,
                  formatter: formatterName
                },
                data: xdata
              }
            ],
            xAxis: [
              {
                type: "value",
                axisLabel: {
                  color: styleObj.fontColor
                },
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                }
              }
            ],
            series
          };
          break;
        case "016":
          option = {
            tooltip,
            calculable: true,
            color: styleObj.colorArr,
            grid,
            legend,
            yAxis: [
              {
                type: "category",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                axisLabel: {
                  interval: 0,
                  rotate: styleObj.angle,
                  color: styleObj.fontColor,
                  formatter: formatterName
                },
                data: xdata
              }
            ],
            xAxis: [
              {
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: styleObj.slineColor
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: styleObj.alineColor
                  }
                },
                axisLabel: {
                  color: styleObj.fontColor,
                  formatter: axisLabel
                }
              }
            ],
            series
          };
          break;
      }
      return option;
    },
    // 获取 filed 根据名字 取field
    getPicField(list, name) {
      let field;
      (list || []).forEach((b) => {
        (b.data || []).forEach((b1) => {
          if (b1.name === name) {
            field = b1.id;
          }
        });
      });
      return field;
    },
    // 根据 field 取到字段设置
    getPicFieldsetData(arr, filed) {
      let setData;
      (arr || []).forEach((itm2) => {
        if (filed === itm2.nameVariable) {
          setData = itm2;
        }
      });
      return setData;
    },
    // 自定义图 公式 解析
    getChartFormula(expressRule) {
      let formulaStr = "";
      if (expressRule.length) {
        const rule = expressRule[0].expressRule || [];
        (rule || []).forEach((element) => {
          if (String(element.type) === "1") {
            // 符号
            formulaStr += element.value;
          }
          if (String(element.type) === "2") {
            if (String(element.value) === "1") {
              formulaStr += "cur";
            } else if (String(element.value) === "2") {
              formulaStr += "prev";
            } else if (String(element.value) === "3") {
              formulaStr += "next";
            }
          } else if (String(element.type) === "3") {
            formulaStr += element.value;
          }
        });
      }
      return formulaStr;
    },
    // 自定义图 层级 字段公式 没有维度 多个数值触发
    getFieldFormula(picDataList, layerId) {
      let formulaStr = "";
      const obj = {};
      // 利用layerId 找到对应的公式字段进行公式解析
      (picDataList || []).forEach((element) => {
        if (element.id === layerId) {
          const rule = typeof element.fieldExpressRule === "string" ? JSON.parse(element.fieldExpressRule) : element.fieldExpressRule;
          if (rule.length) {
            const InnerRule = rule[0].expressRule || [];
            obj.showName = rule[0].showName || element.showName || "";
            obj.showFormat = rule[0].showFormat || element.showFormat || "";
            InnerRule.forEach((element2) => {
              if (String(element2.type) === "1") {
                // 符号
                formulaStr += element2.value;
              } else if (String(element2.type) === "2") {
                // 字段
                formulaStr += element2.value;
              } else if (String(element2.type) === "3") {
                // 常量
                formulaStr += element2.value;
              }
            });
          }
          obj.formula = formulaStr;
        }
      });
      return obj;
    },
    // 字符串转为代码计算
    eval(fn) {
      const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
      return new Fn(`return ${fn}`)();
    },
    // 获取返回的数据条件
    getCon(con) {
      // con.forEach((element) => {

      // });
      this.item.condition = con;
      console.log(con);
      if (this.hasPower) {
        this.mdQueryTotalPicData();
      }
    },
    // 显示条件
    toShow() {
      this.$refs.popBoardFilter.visiable = true;
    },
    // 隐藏
    isHide() {
      this.$emit("isHide", this.item);
    },
    // 全屏按钮触发
    isFull() {
      this.$emit("isFull", this.item);
    },
    // top项确定
    isTopClose(obj) {
      console.log(obj);
      this.item.isTopObj = obj;
      if (this.hasPower) {
        this.mdQueryTotalPicData();
      }
    },
    // 标题穿透
    clickTitle(item, refConditionData) {
      // 全屏不穿透
      if (this.isFullScreen) {
        return;
      }
      let con = [];
      if (refConditionData && refConditionData.length) {
        con = refConditionData;
      }
      // 多模板的单个
      if (item) {
        this.toKip(item, con);
      } else {
        // 单模板
        const re = this.getUrlConfig(this.item.templateId);
        this.toKip(re, []);
      }
    },
    // 根据模板查name,有值返回无值返回空对象
    getUrlConfig(templateId) {
      let item = {};
      if (this.type === "1") {
        // 分类
        item = (this.classifyObj1.templateList || []).find((n) => n.id === templateId);
      } else if (this.type === "2") {
        // 单个模板
        item = { id: templateId, name: this.name };
      } else if (this.type === "3") {
        // 主题
        item = { id: templateId, name: this.name };
      }
      if (item) {
        return { id: templateId, name: item.name };
      }
      return {};
    },
    // 组合穿透条件
    makeupCondition(item) {
      // 图表自带查询条件
      const refConditionData = [];
      if (item.picConditionData.length) {
        item.picConditionData.forEach((element) => {
          if (String(element.fieldType) === "7") {
            if (String(element.valueType) === "3") {
              // 时间的 全局变量
              element.valueText = element.initValueText;
              element.value = element.initValue;
            }
          }
          const c = {
            fieldName: element.name || element.fieldName || "",
            fieldNameVariable: element.fieldNameVariable,
            fieldType: element.fieldType,
            area: "MAIN",
            baseDataType: element.areaObjTypeId || element.baseDataType || "", // id
            baseDataTableType: element.areaObjType || element.baseDataTableType || "", // type
            value: element.value || element.dateValue || "",
            valueText: element.valueText || element.dateValue || "",
            valueType: element.valueType || ""
          };
          refConditionData.push(c);
        });
      }
      // 图表外部查询条件
      if (item.conditionData.length) {
        item.conditionData.forEach((element) => {
          if (String(element.fieldType) === "7") {
            if (String(element.valueType) === "3") {
              this.variables.forEach((element1) => {
                if (String(element1.id) === String(element.value) && String(element1.type) === "3") {
                  element.valueText = element1.name;
                }
              });
            } else {
              element.valueText = element.value;
            }
          }
          const c = {
            fieldName: element.fieldName || "",
            fieldNameVariable: element.fieldNameVariable,
            fieldType: element.fieldType,
            area: "MAIN",
            baseDataType: element.areaObjTypeId || element.baseDataType || "", // id
            baseDataTableType: element.areaObjType || element.baseDataTableType || "", // type
            value: element.value || element.dateValue || "",
            valueText: element.valueText || element.dateValue || "",
            valueType: element.valueType || ""
          };
          let flg = false;
          // 有重复条件 替换
          refConditionData.forEach((element1, index1) => {
            if (element1.fieldNameVariable === c.fieldNameVariable) {
              refConditionData[index1] = c;
              flg = true;
            }
          });
          if (!flg) {
            refConditionData.push(c);
          }
        });
      }
      return refConditionData;
    },
    /**
     * @description: 跳转
     * @param {type} re跳转的对象 conditionData条件
     * @return:
     */
    toKip(re, conditionData) {
      console.log(re);
      console.log(conditionData);
      if (this.isIndex !== "index") {
        if (re.id) {
          // conditionData需要讨论下
          // dataList: [{prop: "name", property: "name", type: "filter", value: "霍元甲", valueText: "霍元甲", fieldType: 1,…}]
          // pageNo: 1
          // pageSize: 10
          // tabId: ""
          const obj = {
            dataList: [],
            pageNo: 1,
            pageSize: 10,
            tabId: ""
          };
          (conditionData || []).forEach((element) => {
            // bug37675
            if (element.value || element.valueText === "未填写") {
              element.prop = element.fieldNameVariable;
              element.property = element.fieldNameVariable;
              element.column = {
                field: element.fieldNameVariable,
                prop: element.fieldNameVariable,
                property: element.fieldNameVariable,
                title: element.fieldName
              };
              element.name = element.fieldName;
              // element.value = element.value;
              // element.valueText = element.valueText;
              // "areaObjTypeId":"180425142356080913","areaObjType":"001"
              // element.areaObjTypeId = element.baseDataType;
              // element.areaObjType = element.baseDataTableType;
              element.type = "filter";
              obj.dataList.push(element);
            }
          });
          sessionStorage.setItem("business_condition", JSON.stringify(obj));
          let urlObj = {};
          if (this.type !== "3") {
            // 业务建模
            urlObj = {
              path: "/businessModel/list",
              query: {
                templateId: re.id,
                templateName: re.name,
                screen: "6"
              }
            };
          } else {
            // 主题
            // themeSet/viewDefault?templateId=334594880483078144&viewId=334595676698292224&templateName=成绩
            const item = this.viewsList.find((a) => a.name === "列表视图");
            urlObj = {
              path: "/themeSet/viewDefault",
              query: {
                templateId: re.id,
                templateName: re.name,
                viewId: item.id,
                screen: "6"
              }
            };
          }
          this.$router.push(urlObj);
        } else {
          this.$message({
            message: "该业务模块未配置权限,请联系管理员！",
            type: "warning"
          });
        }
      }
    },
    // 指标卡穿透 点击数据体
    cardClick(item) {
      const refConditionData = this.makeupCondition(this.item.rspData);
      if (item.conditionDatas && item.conditionDatas.length) {
        (item.conditionDatas || []).forEach((element) => {
          // 启用的条件
          if (String(element.isCondition) === "0") {
            if (String(element.fieldType) === "7") {
              if (String(element.valueType) === "3") {
                element.valueText = element.initValueText;
                element.value = element.initValue;
              } else {
                element.valueText = element.dateValue;
                element.value = element.dateValue;
              }
            }

            const c = {
              fieldName: element.fieldName || "",
              fieldNameVariable: element.fieldNameVariable,
              fieldType: element.fieldType,
              area: "MAIN",
              baseDataType: element.areaObjTypeId || element.baseDataType || "", // id
              baseDataTableType: element.areaObjType || element.baseDataTableType || "", // type
              value: element.value || element.dateValue || "",
              valueText: element.valueText || element.dateValue || "",
              valueType: element.valueType || ""
            };
            let flg = false;
            // 有重复条件 替换
            refConditionData.forEach((element1, index1) => {
              if (element1.fieldNameVariable === c.fieldNameVariable) {
                refConditionData[index1] = c;
                flg = true;
              }
            });
            if (!flg) {
              refConditionData.push(c);
            }
          }
        });
      }
      const templateId = item.sourceId;
      console.log(refConditionData);
      const re = this.getUrlConfig(templateId);
      console.log(re);
      // 穿透
      this.toKip(re, refConditionData);
    },
    // 地图穿透
    mapClick(item) {
      const This = this;
      const refConditionData = This.makeupCondition(this.item.rspData);
      let field = {};
      field = {
        fieldName: item.groupName || item.setDataX.alias || item.setDataX.name || "",
        fieldNameVariable: item.id || item.setDataX.nameVariable,
        fieldType: item.type || item.setDataX.type,
        area: "MAIN",
        baseDataType: "",
        baseDataTableType: "",
        value: item.showName,
        valueText: item.showName
        // valueType: ""
      };
      // 判断 条件是否重复了
      let flg = false;
      refConditionData.forEach((element1, index1) => {
        if (element1.fieldNameVariable === field.fieldNameVariable) {
          refConditionData[index1] = field;
          flg = true;
        }
      });
      if (!flg) {
        refConditionData.push(field);
      }
      // 最终条件
      console.log(refConditionData);
      let re = {};
      if (This.type === "1") {
        // 分类
        re = This.getUrlConfig(This.item.rspData.templateId);
      } else {
        re = This.getUrlConfig(This.id);
      }
      console.log(re);
      // 穿透
      This.toKip(re, refConditionData);
    },
    // 首页跳到 看板
    goDetails() {
      // toModelChartUser?templateId=334234517607526400&templateName=成绩单&type=2
      this.$router.push({ path: "/toModelChartUser", query: { templateId: this.id, templateName: this.item.mdName, type: this.type } });
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  background: rgba(36, 39, 62, 0.6);
  cursor: default;
  text-align: left;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  &:hover {
    .chart-title .pull-right {
      display: block;
    }
  }
  .chart-title {
    // padding: 0 30px;
    height: 20px;
    width: 100%;
    line-height: 20px;
    position: absolute;
    left: -14px;
    top: -16px;
    .pull-left {
      float: left;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      span {
        margin-right: 10px;
      }
    }
    .pull-left:hover {
      color: #3e90fe;
    }
    .pull-right {
      display: none;
      text-align: right;
      float: right;
    }
  }
  .echarts-box {
    // border-top: none;
    // box-shadow: 5px 5px 3px #00EBFE ;
    // padding: 0px 30px;
    width: 100%;
    height: calc(100% - 0px);
    // border-radius: 5px;
  }
  .box-013 {
    // border: 1px #00EBFE solid;
    border-top: none;
    padding: 0px 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .grid-item013 {
      // border: 1px #cccccc solid;
    }
  }
}
.scoll-chart {
  overflow-y: scroll;
}
// 自定义图穿透
.business-chart-menu {
  border-radius: 5px;
  width: auto;
  // background: rgba(0,0,0,0.8);
  background: #ffffff;
  z-index: 999999999;
  position: fixed;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  ul {
    margin: 0;
    padding: 5px 0;
    border-radius: 5px;
    li {
      height: 36px;
      line-height: 36px;
      color: #636c78;
      margin-bottom: 5px;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      font-size: 12px;
    }
    li:hover {
      color: #3e90fe;
      background: rgba(245, 248, 252, 1);
    }
  }
}
.business-chart-menu::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  right: 20px;
  top: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  // border-bottom: 6px solid rgba(0,0,0,0.8);
  border-bottom: 6px solid #ffffff;
}
* {
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 8px;
    background: rgba(36, 39, 62, 0.3);
  }
}
</style>

<style lang="scss">
.chart__popover {
  padding: 0 !important;
  .tip {
    height: 36px;
    line-height: 36px;
    color: #636c78;
    margin-bottom: 5px;
    padding-left: 12px;
    cursor: pointer;
  }
  .tip:hover {
    color: #3e90fe;
    background: rgba(245, 248, 252, 1);
  }
}
</style>
