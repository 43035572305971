<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 18:11:55
 * @LastEditTime: 2022-08-08 14:23:34
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 首页全屏 后跳转的页面
 * @FilePath: \user\src\views\
-->
<template>
  <div class="full-screen">
    <div class="title" ref="title" :style="fontSize">
      <div class="title-1"></div>
      <div class="title-2" :style="w1">{{ tenantName }}</div>
      <div class="title-3"></div>
    </div>
    <div class="conter">
      <div class="conter-con">
        <div class="conter-con-1">
          <!-- <div v-for="item in menuList2" :key="item.id" class="item" :class="item.classFlag ? 'item1' : 'item2'" @click="clickItem(item)">{{ item.name }}</div> -->
          <table border="0">
            <tr v-for="(item, index) in menuList3" :key="index">
              <td v-for="(item1, index1) in item" :key="index1">
                <div class="td-div" @click="clickItem(item1)">
                  <div :class="item1.classFlag ? 'item1' : 'item2'" ref="title1" :style="fontSize1">{{ item1.name }}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { enChart } from "@/api/businessChart";
// import toggleFullScreen from "@/mixins/toggleFullScreen"; // 公共方法
import indexFull from "@/mixins/indexFull/indexFull"; // 公共方法

export default {
  name: "indexFull",
  // mixins: [toggleFullScreen],
  mixins: [indexFull],
  data() {
    return {
      userInfo: {},
      tenantName: "",
      // 原菜单数据
      menuList: [],
      menuList2: [],
      menuList3: [[], [], [], [], [], [], []],
      fontSize: "",
      fontSize1: "",
      w1: ""
    };
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
    this.initWebscok();
    this.tenantName = this.userInfo.tenantName;
    // this.tenantName = "小微企业数字化自助平台";
    // 小微企业数字化自助平台
    this.menuList = cloneDeep(this.$store.getters["main/menuList"]);
    this.makeMenuList();
    // this.addFullScreenListener(this.finish, this.closeFull);
    // this.toggleFullScreen();
    this.$nextTick(() => {
      this.fontSizefun();
      this.widthfun();
      this.fontSizefun1();
    });
  },
  methods: {
    widthfun() {
      const h = this.$refs.title.clientHeight;
      let w1 = h * 0.55 * (this.tenantName.length || 1) + 0;
      w1 = Math.ceil(w1);
      this.w1 = `min-width: ${w1}px;`;
    },
    fontSizefun() {
      const h = this.$refs.title.clientHeight;
      const h1 = h * 0.55;
      this.fontSize = `font-size: ${h1}px;`;
    },
    fontSizefun1() {
      const h = this.$refs.title1[0].clientHeight;
      const h1 = h * 0.45;
      this.fontSize1 = `font-size: ${h1}px;`;
    },
    // 处理菜单数据
    makeMenuList() {
      const code058 = {
        channel: "PC",
        classFlag: false,
        code: "058",
        createTime: "2022-08-04 10:12:44",
        customAttr: "",
        customColor: "#FFC466",
        customIcon: "caiwubaobiao-shixin",
        dataType: 1,
        icon: "058",
        id: "388753459995807754",
        isCommon: "001",
        isShow: 0,
        menuSortClassId: "",
        menuSortId: "376609172067852295",
        name: "财务报表",
        outType: 0,
        outUrl: "",
        refId: "276503041623982080_058",
        sorts: 2,
        styleSize: "",
        tenantId: "376092192173998080",
        userId: "376453803998986240"
      };
      const code040 = {
        channel: "PC",
        classFlag: false,
        code: "040",
        createTime: "2022-08-04 10:12:44",
        customAttr: "",
        customColor: "#60A9EF",
        customIcon: "pingzhengchuli-shixin",
        dataType: 1,
        icon: "040",
        id: "388753459995807753",
        isCommon: "001",
        isShow: 0,
        menuSortClassId: "",
        menuSortId: "376609172067852295",
        name: "凭证处理",
        outType: 0,
        outUrl: "",
        refId: "270550879146344448_040",
        sorts: 1,
        styleSize: "",
        tenantId: "376092192173998080",
        userId: "376453803998986240"
      };
      (this.menuList || []).forEach((item) => {
        if (item.type === 0 || item.icon === "2_10") {
          //  首页不处理
        } else {
          console.log(item);
          //  分类数据 标识 classFlag
          item.classFlag = true;
          if (this.menuList2.length < 42 && item.isShow === 0) {
            this.menuList2.push(item);
          }
          if (item.name === "财务管理" && this.menuList2.length < 42) {
            this.menuList2.push(code040);
            this.menuList2.push(code058);
          }
          (item.dataList || []).forEach((item2) => {
            item2.classFlag = false;
            if (this.menuList2.length < 42 && item2.isShow === 0 && item2.dataType !== 10) {
              this.menuList2.push(item2);
            }
          });
        }
      });

      for (let i = 0; i < 7; i++) {
        this.menuList2.forEach((item, index) => {
          if (index % 7 === i) {
            this.menuList3[i].push(item);
          }
        });
      }
    },
    /**
     * @description: 全屏开启完成事件
     */
    finish() {
      this.$emit("finish");
    },
    // 关闭事件 需要父级处理 这里只会触发
    closeFull() {
      // 返回首页
      const p1 = {
        tenantId: this.userInfo.tenantId,
        userId: this.userInfo.userId,
        path: "/home/index"
      };
      const p = {
        type: "sameScreenAuth",
        value: JSON.stringify(p1)
      };
      enChart.sendWebSocketMessage(p);
      this.$router.push({ path: "/home/index" });
    },
    clickItem(item) {
      // 必须是有看板的数据 才能穿透
      console.log(item);
      if (item.name === "日常办公") {
        // en1 进 待办
        // en2 进去 消息中心
        // en3 进去 会议管理
        const url1 = "/myRead";
        const url2 = "/businessModel/resources?templateId=380596352558911488&date=2022-08-08";
        const p1 = {
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
          path: "2",
          path2: url1,
          path3: url2
        };
        const p = {
          type: "sameScreenAuth",
          value: JSON.stringify(p1)
        };
        enChart.sendWebSocketMessage(p);
        this.$router.push({ path: "/waitdo" });
      } else if (item.refData && item.refData.hasBoard === "0") {
        const p1 = {
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
          path: `/toModelChartUserFull?templateId=${item.refData.id}&type=${item.classFlag && item.refData && item.refData.hasBoard === "0" ? "1" : "2"}&templateName=${item.name}`
        };
        const p = {
          type: "sameScreenAuth",
          value: JSON.stringify(p1)
        };
        enChart.sendWebSocketMessage(p);
        if (item.classFlag && item.refData && item.refData.hasBoard === "0") {
          this.$router.push({ path: "/toModelChartUserFull", query: { templateId: item.refData.id, type: 1, templateName: item.name } });
        } else if (item.refData && item.refData.hasBoard === "0") {
          this.$router.push({ path: "/toModelChartUserFull", query: { templateId: item.refData.id, type: 2, templateName: item.name } });
        }
      } else if (item.code === "058") {
        // intelligent-accounting/reportIndex 财务报表
        // this.$router.push({ path: "/intelligent-accounting/reportIndex" });
        // 中间对应资产负债表
        // 左边间对应资产负债表
        // 中间对应资产负债表
        const url1 = "/sheet?type=2";
        const url2 = "/sheet?type=3";
        const p1 = {
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
          path: "2",
          path2: url1,
          path3: url2
        };
        const p = {
          type: "sameScreenAuth",
          value: JSON.stringify(p1)
        };
        enChart.sendWebSocketMessage(p);
        this.$router.push({ path: "/sheet", query: { type: 1 } });
        // 中间对应资产负债表
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: #3e61d7;
}
.title {
  width: 100%;
  height: 13%;
  // fulltitlebg
  // background: url("../../assets/images/fulltitlebg.jpg") no-repeat 100% 100%;
  // background-size: 100% 100%;
  color: #ffffff;
  text-align: center;
  font-size: 260%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin: 0 auto;
  // &::after{
  //   clear: both;
  // }
  div {
    height: 100%;
  }
  .title-1 {
    background: url("../../assets/images/title1.png") no-repeat 100% 100%;
    background-size: 100% 100%;
    flex: 1;
    // float:left
  }
  .title-2 {
    background: url("../../assets/images/title2.png") no-repeat 100% 100%;
    background-size: 101% 100%;
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // float:left
  }
  .title-3 {
    background: url("../../assets/images/title3.png") no-repeat 100% 100%;
    background-size: 101% 100%;
    flex: 1;
    // float:left
  }
}
.conter {
  width: 100%;
  height: calc(100% - 13%);
  background: url("../../assets/images/fullBackground.jpg") no-repeat 100% 100%;
  background-size: 100% 100%;
  padding: 2% 0;
  display: flex;
  justify-content: center;
}
.conter-con {
  height: 100%;
  width: calc(100% - 16%);
  .conter-con-1 {
    height: 100%;
    width: 100%;
    // writing-mode: vertical-lr;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
table {
  height: 100%;
  width: 100%;
  tr {
    width: 100%;
    td {
      width: calc(100% / 6);
      padding: 0px 10px;
    }
  }
}
.td-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
}
.item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  // fullitem1bg.png
  background: url("../../assets/images/fullitem1bg.png") no-repeat;
  background-size: 100% 100%;
  // display: inline-block;
  // writing-mode: horizontal-tb;
  // font-family: PangMenZhengDao;
  // font-weight: 400;
  // color: #ffffff;
  // text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // height: 9%;
  // width: 13%;
}
.item2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 64%;
  // fullitem1bg.png
  background: url("../../assets/images/fullitem2bg.png") no-repeat;
  background-size: 100% 100%;
  // display: inline-block;
  // writing-mode: horizontal-tb;
  // font-family: SourceHanSansSC;
  // font-weight: bold;
  // color: #ffffff;
  // text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // height: 9%;
  // width: 11%;
}
.item {
  // margin-bottom: 5%;
  // margin-right: 12%;
  // &:nth-child(n + 36) {
  //   margin-right: 0%;
  // }
  flex: 1;
}
</style>
