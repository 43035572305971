var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full-screen" }, [
    _c("div", { ref: "title", staticClass: "title", style: _vm.fontSize }, [
      _c("div", { staticClass: "title-1" }),
      _c("div", { staticClass: "title-2", style: _vm.w1 }, [
        _vm._v(_vm._s(_vm.tenantName)),
      ]),
      _c("div", { staticClass: "title-3" }),
    ]),
    _c(
      "div",
      { staticClass: "conter" },
      [
        _c(
          "el-row",
          {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { gutter: 10 },
          },
          _vm._l(_vm.menuList2, function (item, index) {
            return _c(
              "el-col",
              {
                key: item.id,
                staticClass: "item3",
                attrs: { xs: 12, sm: 12, md: 12, lg: 8, xl: 6, span: 24 },
                nativeOn: {
                  click: function ($event) {
                    return _vm.clickItem(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "grid-content bg-red",
                    class: _vm.className(index),
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item3-3",
                        staticStyle: { height: "90%" },
                      },
                      [
                        item.classFlag
                          ? _c("chart", {
                              attrs: {
                                item: item.dataList[0].dataList[0].refData,
                                type: item.dataList[0].dataList[0].refData
                                  .refType,
                                isIndex: "index",
                                id: item.dataList[0].dataList[0].refId,
                              },
                            })
                          : _c("chart", {
                              attrs: {
                                item: item.dataList[1].refData,
                                type: item.dataList[1].refData.refType,
                                isIndex: "index",
                                id: item.dataList[1].refId,
                              },
                            }),
                      ],
                      1
                    ),
                    _vm.flg
                      ? _c(
                          "div",
                          {
                            ref: "title1",
                            refInFor: true,
                            staticClass: "item3-2",
                            style: _vm.fontSize1,
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }