var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full-screen" }, [
    _c("div", { ref: "title", staticClass: "title", style: _vm.fontSize }, [
      _c("div", { staticClass: "title-1" }),
      _c("div", { staticClass: "title-2", style: _vm.w1 }, [
        _vm._v(_vm._s(_vm.tenantName)),
      ]),
      _c("div", { staticClass: "title-3" }),
    ]),
    _c("div", { staticClass: "conter" }, [
      _c("div", { staticClass: "conter-con" }, [
        _c("div", { staticClass: "conter-con-1" }, [
          _c(
            "table",
            { attrs: { border: "0" } },
            _vm._l(_vm.menuList3, function (item, index) {
              return _c(
                "tr",
                { key: index },
                _vm._l(item, function (item1, index1) {
                  return _c("td", { key: index1 }, [
                    _c(
                      "div",
                      {
                        staticClass: "td-div",
                        on: {
                          click: function ($event) {
                            return _vm.clickItem(item1)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "title1",
                            refInFor: true,
                            class: item1.classFlag ? "item1" : "item2",
                            style: _vm.fontSize1,
                          },
                          [_vm._v(_vm._s(item1.name))]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }