<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-06 16:49:29
 * @LastEditTime: 2024-02-29 19:21:54
 * @LastEditors: Please set LastEditors
 * @Description: 业务建模看板 页面参数 id
 showList数据显示的载体
 showList[{id:图表id,condition:查询条件}]
 * @FilePath: \user\src\views\businessChart\index.vue
-->
<template>
  <div class="chart-box">
    <div class="chart-title">
      <div class="pull-left">
        <en-icon  class="back-icon" name="iconfanhuishangcengji" size="18px"
              color="#A9B5C6" @click.native="handleBackIconClick"></en-icon>
        <div class="header-icon">
            <en-icon  name="iconmoban-yewujianmo1" size="16"
                  color="#FCFCFC"></en-icon>
          </div>
        <span class="title-text">{{name}}</span>
        <div style="cursor: pointer;" v-if="type !== '3'">
          <el-dropdown  trigger="click" @command="picfullChange"><i style="color:#A9B5C6;transform: rotate(90deg)" class="el-icon-more el-icon--right"></i>
              <el-dropdown-menu slot="dropdown" class="reView">
                <el-dropdown-item :command="1" >
                    <div style="display:flex;align-items:center">
                      <div class="putongbak">
                    <en-icon name="iconpingpushitu" size="14px" style="color:#3E90FE;"></en-icon>
                    </div>
                    列表视图
                    <en-icon v-if="!type && '2' !== picType" name="iconyixuan" size="14px"
                      style="color:#3E90FE;margin-left:10px"></en-icon>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :command="2" >
                  <div style="display:flex;align-items:center">
                    <div class="putongbak">
                  <en-icon name="iconkanbanshitu" size="14px" style="color:#A871F6;"></en-icon>
                  </div>
                  看板视图
                  <en-icon v-if="'2' === picType" name="iconyixuan" size="14px"
                    style="color:#3E90FE;margin-left:10px"></en-icon>
                </div>
                </el-dropdown-item>
                <el-dropdown-item :command="4" v-if="viewType==='3'||viewType === '5'">
                  <div style="display:flex;align-items:center">
                    <div class="putongbak">
                  <span class="iconfont" style="color:#FFA32B;font-size: 14px;">&#xe816;</span>
                  </div>
                  日历视图
                  <en-icon v-if="'4' === picType" name="iconyixuan" size="14px"
                    style="color:#3E90FE;margin-left:10px"></en-icon>
                </div>
                </el-dropdown-item>
                <el-dropdown-item :command="5" v-if="viewType==='3'||viewType === '5'">
                  <div style="display:flex;align-items:center">
                    <div class="putongbak">
                  <span class="iconfont" style="color:#6B5CE8;font-size: 14px;">&#xe811;</span>
                  </div>
                  甘特图
                  <en-icon v-if="'5' === picType" name="iconyixuan" size="14px"
                    style="color:#3E90FE;margin-left:10px"></en-icon>
                </div>
                </el-dropdown-item>
                <el-dropdown-item :command="8" v-if="viewType === '5'" >
                  <div style="display:flex;align-items:center">
                    <div class="putongbak">
                  <span class="iconfont" style="color:#3E90FE;font-size: 14px;">&#xe814;</span>
                  </div>
                  资源视图
                  <en-icon v-if="'8' === picType" name="iconyixuan" size="14px"
                    style="color:#3E90FE;margin-left:10px"></en-icon>
                </div>
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- <en-select
          v-if="type !== '3'"
          v-model="picfull"
          placeholder="请选择"
          :data="options"
          :width="140"
          style="display: inline-block;width: 140px;margin-left:20px;"
          @change="picfullChange"
        ></en-select> -->
        <!-- <viewComm v-else :backFlg="false" :templateId="id" viewId="看板视图" :templateName="name" @toParent="getViewList" style="display: inline-block;width: 140px;margin-left:20px;"></viewComm> -->
        <themeViewComm v-else :templateId="templateId" :viewId="viewId" :templateName="templateName"></themeViewComm>
      </div>
      <div class="pull-right">
        <en-image-setting v-if="condition.length > 0" name="shaixuan" size="small" fill="#9367EB" style="margin-left:10px;" @click.native.stop="toShow('popBoardFilter')">
          筛选
        </en-image-setting>
        <en-image-setting v-if="hideList.length > 0" name="yincang-kanban" size="small" fill="#3e90fe" style="margin-left:10px;" @click.native.stop="dialogShow = true">
          显示
        </en-image-setting>
        <en-image-setting name="kanban-huifumoren" size="small" fill="#3e90fe" style="margin-left:10px;" @click.native.stop="recovery">
          恢复排序
        </en-image-setting>
        <el-popover v-if="type === '1'" class="popover-wrap" popper-class="popover-btn-more" placement="bottom" width="400" trigger="click">
          <div class="mod-list">
            <div class="mod" v-for="item in classifyObj.templateList || []" :key="item.id" @click="toDetail(item)">
              <div class="wrap-icon">
                <en-icon :name="getIcon(item.icon).icon" size="18"> </en-icon>
              </div>
              <div class="wrap-title">
                <span :title="item.name">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div slot="reference">
            <en-image-setting name="gengduo" size="small" fill="#3e90fe" style="margin-left:10px;">
              更多
            </en-image-setting>
          </div>
        </el-popover>
        <en-image-setting name="quanping" size="small" fill="#3e90fe" style="margin-left:10px;" @click.native="moreFull">
          全屏
        </en-image-setting>
      </div>
    </div>
    <div ref="chartCenter" class="chart-center">
      <en-result v-if="showList.length === 0" style="background: #FFF;margin: 10px;border-radius: 5px;" type="NoData"> </en-result>
      <div v-else class="chart-con">
        <!-- :vertical-compact="false"紧密排列 下面填充上面空白 -->
        <grid-layout
          v-if="showList.length > 0 && hackReset1"
          :layout.sync="showList"
          :col-num="12"
          :row-height="60"
          :is-draggable="true"
          :is-resizable="true"
          :vertical-compact="false"
          :margin="[0,8]"
          :use-css-transforms="false"
          class="grid-box"
          style="transition:none;"
        >
          <grid-item
            v-for="(item,index) in showList"
            :key="item.id"
            :x="index % 2 === 0 ? item.x : item.x + 0.05"
            :y="item.y"
            :w="item.w - 0.05"
            :h="item.h"
            :i="item.i"
            :minW="2"
            :minH="2"
            class="grid-item"
            @resized="resizedEvent"
            @moved="movedEvent"
          >
            <chart
              :item="item"
              :id="id"
              :name="name"
              :type="type"
              :resized="resized"
              :flag="item.flag"
              :variables="variables"
              :classifyObj="classifyObj"
              :isFullScreen="false"
              @toParent="getData"
              @isHide="hide"
              @isFull="singleFull"
              :viewsList="viewsList"
            ></chart>
          </grid-item>
        </grid-layout>
      </div>
      <!-- <div v-if="fullNumber">
        <div v-for="(item, index) in lineArray" :key="index" class="line-notice" :style="{ top: fullNumber * 70 * (index + 1) - 4 + 'px' }"></div>
      </div> -->
    </div>
    <!-- 查询条件 -->
    <boardFilter v-if="variablesFlg" ref="popBoardFilter" :conditions="condition" :variables="variables" @saveFilter="getCon"> </boardFilter>
    <!-- 全屏 -->
    <fullScreen v-show="isFullScreen" @close="closeFull" @finish="finishFull">
      <template #content>
        <!-- 单图全屏 -->
        <div v-if="finish && fullType === 1" class="full-box">
          <div class="full-title">{{ singleData.name || "" }}</div>
          <div class="full-center">
            <div class="full-chart">
              <chart v-if="hackReset" :item="singleData" :id="id" :type="type" :classifyObj="classifyObj" :isFullScreen="true" :fullType="fullType"></chart>
            </div>
          </div>
          <div class="full-btn">
            <span title="上一页"><en-icon @click.native.stop="pre('single')" size="small" style="color:#fff;" name="fanhui"></en-icon></span>
            <span title="播放" v-if="!play"><en-icon @click.native.stop="startTimer('single')" size="small" style="color:#fff;margin: 0 20px;" name="zanting-kanban"></en-icon></span>
            <span title="暂停" v-else><en-icon size="small" @click.native.stop="closeTimer('single')" style="color:#fff;margin: 0 20px;" name="bofang-kanban"></en-icon></span>
            <span title="下一页"><en-icon @click.native.stop="next('single')" size="small" style="color:#fff;" name="shouqi"></en-icon></span>
          </div>
        </div>
        <!-- 多图全屏 -->
        <div v-if="finish && fullType === 2" class="full-box">
          <div class="full-title">{{ picFullSet.name || name || "" }}</div>
          <div class="full-center">
            <div class="full-chart" style="padding:0;">
              <grid-layout
                v-if="full[fullI].length > 0 && hackReset"
                :layout.sync="full[fullI]"
                :col-num="12"
                :row-height="fullH"
                :is-draggable="false"
                :is-resizable="false"
                :vertical-compact="false"
                :margin="[8, 8]"
                :use-css-transforms="false"
                class="grid-box"
                style="transition:none;"
              >
                <grid-item v-for="item in full[fullI]" :key="item.id" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :minW="2" :minH="2" class="grid-item">
                  <chart v-if="finishFullMore" :item="item" :id="id" :type="type" :classifyObj="classifyObj" :isFullScreen="true" :fullType="fullType"></chart>
                </grid-item>
              </grid-layout>
            </div>
          </div>
          <div class="full-btn">
            <span title="上一页"><en-icon @click.native.stop="pre" size="small" style="color:#fff;" name="fanhui"></en-icon></span>
            <span title="播放" v-if="!play"><en-icon @click.native.stop="startTimer" size="small" style="color:#fff;margin: 0 20px;" name="zanting-kanban"></en-icon></span>
            <span title="暂停" v-else><en-icon size="small" @click.native.stop="closeTimer" style="color:#fff;margin: 0 20px;" name="bofang-kanban"></en-icon></span>
            <span title="下一页"><en-icon @click.native.stop="next" size="small" style="color:#fff;" name="shouqi"></en-icon></span>
          </div>
        </div>
      </template>
    </fullScreen>
    <!-- 已隐藏看板 -->
    <hiddenChart v-if="dialogShow" :show="dialogShow" :hideList="hideList" @toShow="hiddenChartClose"></hiddenChart>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";
import VueGridLayout from "vue-grid-layout";
import { enChart } from "@/api/businessChart";
import fullScreen from "@/components/full-screen";
// import viewComm from "@/views/themeSet/components/viewComm";
import themeViewComm from "@/views/themeSet/components/themeViewComm";
import chart from "./components/chart";
import boardFilter from "./components/boardFilter";
import hiddenChart from "./components/hiddenChart";

export default {
  name: "BusinessChart",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chart,
    boardFilter,
    fullScreen,
    hiddenChart,
    // viewComm,
    themeViewComm
  },
  data() {
    return {
      picType: this.$route.query.picType || "2", // 看板 or 列表
      templateId: this.$route.query.templateId,
      templateName: this.$route.query.templateName,
      viewType: this.$route.query.viewType,
      id: "", // 模板或者分类id
      // 1分类和 2模板区分 3主题 主题的时候options需要改变
      type: "",
      picfull: "1",
      options: [
        { value: "2", label: "普通视图" },
        { value: "1", label: "看板视图" }
      ],
      // 需要显示的图形集合
      showList: [],
      // 隐藏的图形集合
      hideList: [],
      // 所有图形
      allList: [],
      // 用户习惯图形
      userList: [],
      // 所有显示图形id集合
      userId: [],
      resized: false,
      // 查询条件
      condition: [],
      // 全局变量
      variables: [],
      variablesFlg: false,
      // 模板分类
      classify: [],
      // 当前 模板 或者分类的名称
      name: "",
      // 当前分类数据
      classifyObj: {},
      // 全屏的时候展示的最大y值
      fullNumber: 0,
      // 全屏的时候展示单元格高
      fullH: 60,
      // 分割线个数
      lineArray: [],
      maxY: 0, // 图形占的最大y值
      isFullScreen: false, // 是否全屏
      isloading: true, // 数据还在加载中请稍后
      finish: false, // 全屏组件渲染v-if
      // 单图全屏所需要的数据
      singleI: 0, // 单图全屏 当前展示的showList index
      singleData: {}, // 单图全屏展示图形的数据
      play: false, // 播放状态true播放 false暂停
      fullType: 1, // 全屏类型单图为1 多图为2
      chartsTimer: null, // 全屏定时器
      hackReset: true, // 重置组件
      hackReset1: true, // 非全屏下的重置组件
      full: [], // 多图显示用的list[[{},{}],[{}]]
      fullI: 0, // 多图显示的index
      finishFullMore: false, // 多图全屏图形上面的v-if
      picFullSet: {}, // 全屏设置
      timer: null, // 重新请求数据定时器
      dialogShow: false, // 已隐藏的看板 弹框的v-if
      viewsList: [] // 主题视图数据
    };
  },
  mounted() {
    // 如果路由里面含有里面有
    const { templateId, templateName, type } = this.$route?.query || {};
    if (templateId) {
      this.id = templateId;
    }
    if (templateName) {
      this.name = templateName;
    }
    if (type) {
      this.type = String(type);
      console.log(this.type, "00000");
    }
    if (this.type === "3") {
      // 主题适配
    }
    this.init(true);
    // window.onresize = () => {
    //   debugger;
    //   this.resized = !this.resized;
    //   this.calculation();
    // };
  },
  methods: {
      // 返回按钮点击
      handleBackIconClick() {
      this.$router.back();
    },
    // 计算全屏的高度
    calculation() {
      // 内容区域的宽度
      const nw = this.$refs.chartCenter.offsetWidth;
      // 缩放系数
      let ratio = 1;
      ratio = nw / window.screen.width;
      console.log(ratio, "ratio");
      // 获取屏幕宽高
      const pW = window.screen.width * ratio;
      const pH = window.screen.height * ratio;
      console.log(pW, "pW", pH);
      // 全屏的 单元格 高度
      const qh = parseInt((60 * pW) / nw, 10);
      this.fullH = qh;
      // 一屏 多少个y
      this.fullNumber = parseInt((pH - 120) / (qh), 10);
      this.line();
    },
    // 设置分屏线数量
    line() {
      // 划线的个数
      console.log(this.fullNumber, "line");
      const g = Math.ceil((this.maxY * 70 - 10) / (this.fullNumber * 70));
      this.lineArray.length = g;
    },
    // 初始方法 once为true 第一次条用 type==full为全屏是渲染数据 需要保留查询条件
    async init(once, type) {
      console.log(once, type, "init");
      // 查询 管理端分屏线
      this.calculation();
      const p = { key_: "picfull_fh" };
      const data = (await enChart.queryComConfig(p)) || {};
      if (JSON.stringify(data) === "{}") {
        //
      } else {
        let ratio = 1;
        ratio = window.innerWidth / window.screen.width;
        // 获取屏幕宽高
        const pH = window.screen.height * ratio;
        this.fullNumber = Number(data.picfull_fh);
        this.fullH = (pH - 120 + 10) / this.fullNumber - 10;
      }

      // 查询业务建模分类及其模板
      if (this.type === "1" && once) {
        await this.mdUserQueryTemplateClassify();
      }
      // 查询全部图形
      await this.mdQueryTotalPicList();
      // 全屏重新获取数据不需要查询设置条件
      if (!type) {
        // 查询条件
        await this.mdQueryCondition();
      }
      // 查询用户习惯位置
      await this.mdQueryUserPicCoordinate();
      // 查询全局变量
      if (once) {
        await this.queryRbacGloableVariableList();
      }
      // 查询全屏设置
      this.queryComConfig();
      // 处理数据
      this.handle(type);
    },
    // 查询业务建模分类及其模板
    async mdUserQueryTemplateClassify() {
      const This = this;
      const p = { withTemplate: 0 };
      this.classify = await enChart.mdUserQueryTemplateClassify(p);
      const res = this.classify.filter((b) => b.id === This.id);
      console.log(res, "uppppp");
      if (res.length) {
        this.classifyObj = res[0];
        this.name = this.classifyObj.name;
      }
    },
    // 看板视图切换
    picfullChange(command) {
      if (command === 4) { // 任务俩的日历视图
       //
          this.requestSaveConfig(`picList-${this.templateId}`, "4");
          this.$router.push({
            path: "/businessModel/calendar",
            query: {
              templateId: this.templateId,
              templateName: this.templateName || this.name,
              page: this.picType,
              picType: "4",
              viewType: this.$route.query.viewType
            }
          });
    } else if (command === 5) { // 甘特图
       // 切换看板/列表视图
      this.requestSaveConfig(`picList-${this.templateId}`, "5");
      this.$router.push({
        path: "/businessModel/gantt",
        query: {
          templateId: this.templateId,
          templateName: this.templateName,
          page: this.picType,
          picType: "5",
          viewType: this.$route.query.viewType
        }
      });
    } else if (command === 1) {
      // 如果只有列表视图，已默认显示，无需触发点击切换重加载
        // 切换看板/列表视图
        // 区分一下 哪里进入的
        if ((this.$route.query.type === "2" || this.$route.query.picType === "2") && this.$route.query.templateName) {
          this.requestSaveConfig(`picList-${this.templateId}`, "1");
          console.log(2345);
          this.$router.push({
            path: "/businessModel/list",
            query: {
              templateId: this.$route.query.templateId,
              templateName: this.$route.query.templateName,
              viewType: this.$route.query.viewType,
              type: this.$route.query.type,
              picType: "1"
            }
          });
        } else {
          this.$router.push({
            path: "/businessModel/boardList",
            query: {
              id: this.templateId,
              type: "1"
            }
          });
        }
    } else if (command === 2) {
      // 如果只有列表视图，已默认显示，无需触发点击切换重加载
        // 切换看板/列表视图
        this.requestSaveConfig(`picList-${this.templateId}`, "2");
        this.$router.push({
          path: "/toModelChartUser",
          query: {
            templateId: this.templateId,
            templateName: this.templateName,
            picType: "2",
            type: "2",
            viewType: this.$route.query.viewType
          }
        });
    } else if (command === 8) {
      // 如果只有列表视图，已默认显示，无需触发点击切换重加载
        // 切换看板/列表视图
        this.requestSaveConfig(`picList-${this.templateId}`, "2");
        this.$router.push({
          path: "/businessModel/resources",
          query: {
            templateId: this.templateId,
            templateName: this.templateName,
            picType: "8",
            viewType: this.$route.query.viewType
          }
        });
    } else {
      // 展开视图设置弹窗
      this.isShowViewSetting = true;
    }
      // 1 看板视图 2 列表视图
      // if (command === "1") {
      //   return;
      // }
      // enChart.saveUserConfig({ configKey: `picList-${this.id}`, configValue: this.picfull });
      // console.log(this.type, "this.typethis.type");
      // if (this.type === "2") {
      //   this.$router.push({
      //     path: "/businessModel/list",
      //     query: {
      //       templateId: this.id,
      //       templateName: this.name,
      //       page: this.picfull,
      //       viewType: this.$route.query.viewType
      //     }
      //   });
      // } else {
      //   this.$router.push({ path: "/businessModel/boardList", query: { id: this.id } });
      // }
    },
    async requestSaveConfig(key, value) {
      const res = await enService.saveUserConfig({
        configKey: key,
        configValue: value
      });
      return res;
    },
    // 查询用户习惯
    async mdQueryUserPicCoordinate() {
      const This = this;
      const p = { refId: This.id };
      This.userList = (await enChart.mdQueryUserPicCoordinate(p)) || [];
      This.userId.length = 0;
      (This.userList || []).forEach((element) => {
        This.userId.push(element.totalPicId);
      });
    },
    // 查询模板条件
    async mdQueryCondition() {
      const p = {
        // 适配主题
        refType: this.type === "3" ? "2" : this.type,
        refId: this.id,
        id: this.id
      };
      this.condition = (await enChart.mdQueryCondition(p)) || [];
    },
    // 查询所有图表
    async mdQueryTotalPicList() {
      const p = {
        // 适配主题
        refType: String(this.type) === "3" ? "2" : this.type,
        refId: this.id
      };
      this.allList = (await enChart.mdQueryTotalPicList(p)) || [];
    },
    // 查询全局变量
    async queryRbacGloableVariableList() {
      this.variables = await enChart.queryRbacGloableVariableList();
      this.variablesFlg = true;
    },
    // 查询全屏设置
    async queryComConfig() {
      const p = { key_: `picfull${this.id}` };
      const rspData = await enChart.queryComConfig(p);
      if (JSON.stringify(rspData || {}) === "{}") {
        //
      } else {
        this.picFullSet = JSON.parse(rspData[`picfull${this.id}`]);
      }
    },
    // 处理数据
    handle(type) {
      const This = this;
      this.hackReset1 = false;
      this.$nextTick(async() => {
        this.hackReset1 = true;
        let showList = [];
        This.hideList = [];
        if (This.userList.length) {
          // 有用户习惯
          This.allList.forEach((element) => {
            if (This.userId.indexOf(element.id) > -1) {
              // 图形存在于用户习惯中 取用户习惯坐标替换
              const key = This.userId.indexOf(element.id);
              element.picSize = This.userList[key].coordinate;
              showList.push(element);
            } else {
              This.hideList.push(element);
            }
          });
          if (showList.length === 0) {
            // 所有图被删除
            showList = this.allList;
          }
          // 查询 用户端分屏线
          const data1 = (await enChart.queryUserConfig({ configKey: "picfull_fh" })) || {};
          if (JSON.stringify(data1) === "{}") {
            //
          } else {
            let ratio = 1;
            ratio = window.innerWidth / window.screen.width;
            // 获取屏幕宽高
            const pH = window.screen.height * ratio;
            this.fullNumber = Number(data1.value);
            this.fullH = (pH - 120 + 10) / this.fullNumber - 10;
          }
        } else {
          // 无用户习惯
          showList = this.allList;
        }
        // 处理坐标数据
        showList.forEach((element) => {
          const obj = JSON.parse(element.picSize);
          console.log(obj, "showList");
          obj.x = Number(obj.x);
          obj.y = Number(obj.y);
          obj.w = Number(obj.width);
          obj.h = Number(obj.height);
          this.$set(element, "x", obj.x);
          this.$set(element, "y", obj.y);
          this.$set(element, "w", obj.w);
          this.$set(element, "h", obj.h);
          this.$set(element, "flag", false);
          this.$set(element, "condition", []);
          This.condition.forEach((element2) => {
            if (element2.totalPicId.indexOf(element.id) > -1) {
              element.condition.push(element2);
            }
          });
        });
        // 排序
        showList.sort((a, b) => {
          if (Number(a.y) - Number(b.y) === 0) {
            return Number(a.x) - Number(b.x);
          }
          return Number(a.y) - Number(b.y);
        });
        showList.forEach((element, index) => {
          this.$set(element, "i", index);
        });
        // 如果是全屏操作 保留之前的查询条件 和 top项
        if (type) {
          //
          this.showList.forEach((element) => {
            showList.forEach((element2) => {
              if (element.id === element2.id) {
                if (element.condition) {
                  element2.condition = element.condition;
                }
                if (element.isTopObj) {
                  element2.isTopObj = element.isTopObj;
                }
              }
            });
          });
        }
        this.showList = showList;
        if (showList.length) {
          this.maxY = this.showList[this.showList.length - 1].y + this.showList[this.showList.length - 1].h;
        }
        // this.calculation();
        this.line();

        console.log("showList", this.showList);
        console.log("hideList", this.hideList);
      });
    },
    // 移动结束
    movedEvent() {
      this.mdSaveUserPicCoordinate();
    },
    // 缩放结束
    resizedEvent() {
      this.resized = !this.resized;
      this.mdSaveUserPicCoordinate();
    },
    // 保存用户看板的习惯
    async mdSaveUserPicCoordinate(init, list) {
      if (init) {
        const p = { refId: this.id, picCoordinate: JSON.stringify([]) };
        await enChart.mdSaveUserPicCoordinate(p);
        this.init();
      } else {
        const picCoordinate = [];
        // 先排列下顺序再保存
        this.showList.sort((a, b) => {
          if (Number(a.sort.y) - Number(b.sort.y) === 0) {
            return Number(a.sort.x) - Number(b.sort.x);
          }
          return Number(a.sort.y) - Number(b.sort.y);
        });
        this.showList.forEach((element, index) => {
          element.i = index;
          const obj = {
            x: element.x,
            y: element.y,
            width: element.w,
            height: element.h
          };
          picCoordinate.push({ totalPicId: element.id, coordinate: JSON.stringify(obj) });
        });
        this.maxY = this.showList[this.showList.length - 1].y + this.showList[this.showList.length - 1].h;
        // list 隐藏变为显示的图形 需要排序的
        if (list) {
          let maxY = this.maxY;
          list.forEach((element, index) => {
            const flg = index + 1;
            if (flg !== 1 && flg % 2 === 1) {
              maxY += 5;
            }
            const style = {
              x: flg % 2 === 1 ? 0 : 6,
              y: maxY,
              height: 5,
              width: 6
            };
            const obj = {
              totalPicId: element.id,
              coordinate: JSON.stringify(style)
            };
            picCoordinate.push(obj);
          });
          const p = { refId: this.id, picCoordinate: JSON.stringify(picCoordinate) };
          await enChart.mdSaveUserPicCoordinate(p);
          this.calculation();
          const p1 = {
            configKey: "picfull_fh",
            configValue: this.fullNumber
          };
          await enChart.saveUserConfig(p1);
          this.init();
        } else {
          const p = { refId: this.id, picCoordinate: JSON.stringify(picCoordinate) };
          enChart.mdSaveUserPicCoordinate(p);
          this.calculation();
          const p1 = {
            configKey: "picfull_fh",
            configValue: this.fullNumber
          };
          await enChart.saveUserConfig(p1);
          this.line();
        }
      }
    },
    // 接收组件回传的数据信息 把数据挂到showList上面用来全屏展示使用
    getData(rspData) {
      let flg = false;
      this.showList.forEach((element) => {
        if (rspData.picData.id === element.id) {
          element.rspData = rspData;
        }
        if (!element.rspData) {
          flg = true;
        }
      });
      // 数据还没有加载完全
      if (flg === true) {
        this.isloading = true;
      } else {
        this.isloading = false;
      }
    },
    // 恢复默认排序
    recovery() {
      const This = this;
      this.$confirm("确定重置看板的位置、大小和显隐属性么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          This.mdSaveUserPicCoordinate("init");
        })
        .catch();
    },
    // 获取返回的数据条件
    getCon(con) {
      // 新条件替换旧条件
      this.condition = con;
      const picList = [];
      this.showList.forEach((element) => {
        this.$set(element, "condition", []);
        this.condition.forEach((element2) => {
          if (element2.totalPicId.indexOf(element.id) > -1) {
            picList.push(element.id);
            element.condition.push(element2);
          }
        });
      });
      // 触发 监听器
      this.showList.forEach((element) => {
        if (picList.indexOf(element.id) > -1) {
          element.flag = !element.flag;
        }
      });
    },
    // 显示条件 显示更多
    toShow(item) {
      // 显示更多
      if (item === "more") {
        this.$refs[item].visible = true;
      } else {
        // 显示条件
        this.$refs[item].visiable = true;
      }
    },
    // 跳转到详细数据
    toDetail(detail) {
      console.log(detail);

      this.$router.push({
        path: "/toBusinessList",
        query: {
          templateId: detail.id,
          templateName: detail.name
        }
      });
    },
    /**
     * @description: 图形隐藏按钮触发
     * 在showList中去除该项 在hideList中添加该项
     * @param {type} 隐藏图形的数据体
     */
    hide(item) {
      if (this.showList.length === 1) {
        this.$message({
          message: "至少展示一张图形",
          type: "warning"
        });
      } else {
        this.showList.splice(item.i, 1);
        this.hideList.push(item);
        this.mdSaveUserPicCoordinate();
      }
    },
    /**
     * @description: 单图全屏触发
     * @param {item} 数据体
     * @return:
     */
    singleFull(item) {
      this.singleI = item.i;
      this.singleData = this.showList[this.singleI];
      this.fullType = 1;
      this.openFull(item);
    },
    /**
     * @description: 打开全屏
     * @param {type}
     * @return: isFullScreen = true;
     */
    openFull(item) {
      if (this.isloading) {
        this.$message({
          message: "数据还在加载中请稍后",
          type: "warning"
        });
      } else {
        if (item.type === "bi") {
          this.hackReset1 = false;
        }
        const elem = document.body;
        if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.requestFullScreen) {
          elem.requestFullscreen();
        } else {
          // 浏览器不支持全屏API或已被禁用
        }
        this.isFullScreen = true;
      }
    },
    /**
     * @description: 全屏组件渲染完成
     * @param {type}
     * @return:
     */
    finishFull() {
      this.finish = true;
    },
    /**
     * @description: 关闭全屏组件触发
     * @param {type}
     * @return: isFullScreen = false;
     */
    closeFull() {
      this.hackReset1 = true;
      const elem = document;
      this.finish = false; // 全屏组件渲染v-if
      this.isFullScreen = false; // 是否全屏模式
      this.finishFullMore = false; // 图形上面的v-if
      this.play = false; // 播放状态true播放 false暂停
      clearInterval(this.chartsTimer);
      clearInterval(this.timer);
      if (elem.webkitCancelFullScreen) {
        elem.webkitCancelFullScreen();
      } else if (elem.mozCancelFullScreen) {
        elem.mozCancelFullScreen();
      } else if (elem.cancelFullScreen) {
        elem.cancelFullScreen();
      } else if (elem.exitFullscreen) {
        elem.exitFullscreen();
      } else {
        // 浏览器不支持全屏API或已被禁用
      }
    },
    /**
     * @description: 上一页
     */
    pre(param) {
      if (param === "single") {
        // 单图
        this.hackReset = false;
        this.$nextTick(() => {
          this.hackReset = true;
          this.singleI -= 1;
          if (this.singleI === -1) {
            this.singleI = this.showList.length - 1;
          }
          this.singleData = this.showList[this.singleI];
        });
      } else {
        // 多图
        this.hackReset = false;
        this.finishFullMore = false;
        this.$nextTick(() => {
          this.hackReset = true;
          this.fullI -= 1;
          if (this.fullI === -1) {
            this.fullI = this.full.length - 1;
          }
          // grid-layout 完成后再进行
          this.fullChart();
        });
      }
    },
    /**
     * @description: 下一页
     */
    next(param) {
      if (param === "single") {
        // 单图
        this.hackReset = false;
        this.$nextTick(() => {
          this.hackReset = true;
          this.singleI += 1;
          if (this.singleI === this.showList.length) {
            this.singleI = 0;
          }
          this.singleData = this.showList[this.singleI];
        });
      } else {
        // 多图
        this.hackReset = false;
        this.finishFullMore = false;
        this.$nextTick(() => {
          this.hackReset = true;
          this.fullI += 1;
          if (this.fullI === this.full.length) {
            this.fullI = 0;
          }
          // grid-layout 完成后再进行
          this.fullChart();
        });
      }
    },
    /**
     * @description: 多图全屏 布局延时 不然不能正常加载
     */
    fullChart() {
      // this.$nextTick(() => {
      // this.finishFullMore = true;
      setTimeout(() => {
        this.finishFullMore = true;
      }, 100);
      // });
    },
    /**
     * @description: 开启定时器
     */
    startTimer(param) {
      this.play = true;
      this.reRequest();
      if (param === "single") {
        this.chartsTimer = setInterval(() => {
          this.next("single");
        }, 5000);
      } else {
        this.chartsTimer = setInterval(() => {
          this.next();
        }, 5000);
      }
    },
    /**
     * @description: 清除定时器
     */
    closeTimer() {
      this.play = false;
      clearInterval(this.chartsTimer);
      clearInterval(this.timer);
    },
    /**
     * @description: 开启多图全屏
     */
    moreFull() {
      this.finishFullMore = false;
      this.fullCalculation();
      this.fullType = 2;
      this.fullI = 0;
      this.openFull();
      this.fullChart();
    },
    /**
     * @description: 计算一屏显示图形
     * @param {type}
     * @return: 得到一个[[{},{}],[{}]]这样的数据
     */
    fullCalculation() {
      // this.fullNumber
      this.full = [];
      for (let index = 0; index < this.lineArray.length; index++) {
        this.full.push([]);
      }
      this.full.forEach((element, index) => {
        this.showList.forEach((element2) => {
          const max = (index + 1) * this.fullNumber;
          const min = index * this.fullNumber;
          const y = element2.y + element2.h;
          if (y <= max && y > min) {
            element.push({ ...element2 });
            element[element.length - 1].y = element[element.length - 1].y - min;
          }
        });
      });
      console.log("full", this.full, this.fullNumber);
    },
    // 重新请求数据
    reRequest() {
      if (this.picFullSet.open === "1") {
        // 需要重新请求
        const second = Number(this.picFullSet.second) * 1000;
        this.timer = setInterval(() => {
          if (window.navigator.onLine) {
            this.init(false, "full");
            this.fullCalculation();
          } else {
            clearInterval(this.timer);
          }
        }, second);
      } else {
        // 请求一个接口 保证全屏不会断网
        this.timer = setInterval(() => {
          if (window.navigator.onLine) {
            enChart.queryRbacGloableVariableList();
          } else {
            clearInterval(this.timer);
          }
        }, 300000); // 5分钟一次
      }
    },
    // 已隐藏看板 回调
    hiddenChartClose(list) {
      console.log(list);
      if (list.length) {
        // list存在保存到用户习惯
        this.mdSaveUserPicCoordinate(null, list);
      }
      this.dialogShow = false;
    },
    // 获取 视图数据
    getViewList(list) {
      this.viewsList = list;
    },
    /**
     * @description: 获取图标方法
     */
    getIcon(icon) {
      if (icon <= 20) {
        return { icon: `bus-board-icon${icon}` };
      }
      return { icon: `bus-ywmb-icon${icon}` };
    }
  }
};
</script>

<style lang="scss" scoped>
.view-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.putongbak {
  width: 22px;
  height: 22px;
  background: rgba(62, 144, 254, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:10px;
}

.liebiaobak {
  width: 22px;
  height: 22px;
  background: rgba(168, 113, 246, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:10px;
}
.el-dropdown-menu .el-popper{
  left:195px !important;
}
.el-dropdown-menu .el-dropdown-menu__item{
  width: 180px;
}
.chart-box {
  height: 100%;
  width: 100%;
  position: relative;
  .chart-title {
    border-radius: 5px;
    padding: 0 20px;
    // width: calc(100% - 20px);
    margin: 0 auto;
    height: 50px;
    background: #ffffff;
    line-height: 50px;
    .pull-left {
      float: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      .title-text{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #1A1C1E;
      }
    }
    .pull-right {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 0;
    }
  }
  .chart-center {
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 58px);
    position: relative;
    .chart-con {
      width: 100%;
      position: relative;
      .grid-item {
        background: #ffffff;
        border-radius: 5px;
        overflow: hidden;
        /deep/.vue-resizable-handle{
          display: none;
        }
        &:hover{
          /deep/.vue-resizable-handle{
            display: block;
          }
        }
      }
    }
    // 分割线
    .line-notice {
      border-bottom: 1px dashed #7c818a;
      position: absolute;
      left: 0;
      right: 0;
      height: 10px;
      z-index: 999;
      width: 80%;
      margin: 0 auto;
    }
    .line-notice::before {
      content: "分屏线";
      line-height: 20px;
      color: #adafb1;
      font-size: 12px;
      text-align: center;
      width: 100%;
      display: block;
      width: 50px;
      margin-left: calc(50% - 25px);
    }
  }
  .more {
    position: absolute;
    top: 140px !important;
    right: 100px !important;
  }
}
// 全屏设置样式
.full-box {
  width: 100%;
  height: 100%;
  position: relative;
  .full-title {
    position: relative;
    z-index: 100;
    height: 60px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    background: #232c3d;
    line-height: 60px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .full-center {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 60px;

    .full-chart {
      width: 100%;
      height: 100%;
      padding: 20px;
    }
    .grid-item {
      background: #232c3d;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  .full-btn {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    line-height: 60px;
  }
}
.popover-wrap {
  display: inline-block;
  cursor: pointer;
}
</style>
<style lang="scss">
body {
  // 避免产生留白
  background: #1d2532;
}
.popover-btn-more {
  // background-color: #666975;
  background-color: rgba(102, 105, 117, 0.8);
  width: 400px;
  max-height: 228px;
  border: 1px solid rgba(102, 105, 117, 0.8);
  color: #fff !important;
  font-size: 12px !important;
  .mod-list {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 204px;
    // overflow-y: auto;
    .mod {
      width: 180px;
      height: 60px;
      padding: 10px;
      display: flex;
      align-items: center;
      text-align: left;
      cursor: pointer;
      border-radius: 5px;
      .wrap-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .wrap-title {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff !important;
        font-size: 12px !important;
        padding-left: 10px;
      }
      &:nth-child(3n-1) {
        .wrap-icon {
          background-color: #49bef2;
        }
      }
      &:nth-child(3n) {
        .wrap-icon {
          background-color: #4ed3c3;
        }
      }
      &:nth-child(3n + 1) {
        .wrap-icon {
          background-color: #f6a85c;
        }
      }
      &:hover{
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .popper__arrow {
    border-bottom-color: rgba(102, 105, 117, 0.8) !important;
  }
  .popper__arrow::after {
    border-top-color: rgba(102, 105, 117, 0.8) !important;
    border-bottom-color: rgba(102, 105, 117, 0.8) !important;
  }
}
.header-icon{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 11px;
      margin-left: 10px;
      background: linear-gradient(-30deg, #3E90FE, #5FA5FF);
      border-radius: 8px;

    }
.putongbak {
  width: 22px;
  height: 22px;
  background: rgba(62, 144, 254, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  text-align: center;
}
</style>
