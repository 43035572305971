<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 16:35:40
 * @LastEditTime: 2021-09-28 16:20:46
 * @LastEditors: Please set LastEditors
 * @Description: 指标卡直线
 * @FilePath: \user\src\views\businessChart\components\cardLine.vue
-->
<template>
  <div class="card-line">
    <div class="line-conter">
      <div class="cardblend-linear">
        <div class="col-bgs">
          <div class="col-mainnum">
            <div class="col-tits" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">
              <span v-if="item.iconSet.align === '2' || item.iconSet.align === '4'" class="col-txts">{{ item.title }} </span>
              <span style="padding:5px;border-radius: 50%;" :style="{ background: item.iconSet.color }" :class="item.iconSet.align === '3' || item.iconSet.align === '4' ? 'inline' : 'block'">
                <en-icon
                  :name="'bus-board-icon' + item.iconSet.icon"
                  :size="item.iconSet.size || '32'"
                  style="color:#FFF"
                >
                </en-icon>
              </span>
              <span v-if="item.iconSet.align === '1' || item.iconSet.align === '3'" class="col-txts">{{ item.title }}</span>
            </div>
            <div class="col-num" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">
              {{ valueStr }}
            </div>
          </div>
          <div :style="{ background: progressbg }" class="col-progress progress">
            <div :style="{ width: ratio + '%', background: item.yData[0].color }" class="progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardLine",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      line() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      line() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      line() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      line() {
        return false;
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: 0, // 取出的真实值
      valueStr: "", // 显示值
      contrast: 0, // 对比值
      zbData: [],
      ratio: 0
    };
  },
  mounted() {
    console.log(this.item);
    console.log(this.picData);
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
    } else if (showType === "2") {
      // 百分百
      this.valueStr = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.valueStr = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.valueStr = thousandFormat(Number(this.value).toFixed(decimal));
    }
    this.calculation();
  },
  methods: {
    // 计算百分比
    calculation() {
      // 取出对比值
      if (this.item.yData2 && this.item.yData2.length) {
        const controlNameVariable2 = this.item.yData2[0].controlNameVariable;
        const controlName2 = this.item.yData2[0].controlName;
        (this.zbData || []).forEach((element) => {
          if (element.id === controlNameVariable2 && element.name === controlName2) {
            this.contrast = element.value;
          }
        });
      }
      // 得到需要展示的值
      if (String(this.item.yData[0].controlType) === "5") {
        this.ratio = 100;
      } else if (this.contrast === 0) {
        this.ratio = 100;
      } else {
        // 对比值 存在, 按比例处理
        let bili = this.value / this.contrast;
        if (bili < 0) {
          bili = 0;
          this.ratio = 0;
        } else if (bili > 1) {
          bili = 1;
          this.ratio = 100;
        } else {
          this.ratio = bili * 100;
        }
      }
    },
    progressbg() {
      let color = "";
      if (this.item.yData2 && this.item.yData2.length) {
        color = this.item.yData2[0].color;
      }
      return color;
    },
    // 穿透事件
    through() {
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-line {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .line-conter {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    .col-bgs {
      width: 100%;
      margin: 0 auto;
      padding: 40px 0;
      .col-mainnum {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        align-items: flex-end;
      }
      // 进度条
      .col-progress {
        height: 8px;
        background: #e0e7f3;
        box-shadow: none;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 4px;
        .progress-bar {
          border-radius: 4px;
          background: #72ade9;
          box-shadow: none;
          float: left;
          height: 100%;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          text-align: center;
          width: 0;
        }
      }
    }
  }
}
.inline {
  display: inline-block;
}
.block {
  display: block;
}
</style>
