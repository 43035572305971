<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 18:11:55
 * @LastEditTime: 2024-01-16 15:51:46
 * @LastEditors: linshanfeng
 * @Description: 首页全屏 后跳转的页面
 * @FilePath: \user\src\views\
-->
<template>
  <div class="full-screen">
    <div class="title" ref="title" :style="fontSize">
      <div class="title-1"></div>
      <div class="title-2" :style="w1">{{ tenantName }}</div>
      <div class="title-3"></div>
    </div>
    <div class="conter">
      <!-- <div class="conter-top"></div>
      <div class="conter-top"></div> -->
      <!-- <div v-for="item in menuList2" :key="item.id" class="item3" @click="clickItem(item)">
        <div class="item3-1">
          <img style="width:100%;" :src="(item.src || url)">
          <span ref="title1" :style="fontSize1">{{ item.name }}</span > -->
      <!-- <chartFull :id="item.refData.id" :name="item.name" :type="item.classFlag?'1':'2'" ></chartFull> -->

      <!-- </div>
      </div>  -->
      <el-row :gutter="10" style="width:100%;height:100%;">
        <el-col v-for="(item, index) in menuList2" :key="item.id" class="item3" @click.native="clickItem(item)" :xs="12" :sm="12" :md="12" :lg="8" :xl="6" :span="24">
          <div class="grid-content bg-red" style="height:100%;" :class="className(index)">
            <!-- <img style="width:100%;" :src="item.src || url" /> -->
            <!-- <span v-if="flg" ref="title1" :style="fontSize1">{{ item.name }}</span> -->
            <div class="item3-3" style="height: 90%;">
              <chart v-if="item.classFlag" :item="item.dataList[0].dataList[0].refData" :type="item.dataList[0].dataList[0].refData.refType" isIndex="index" :id="item.dataList[0].dataList[0].refId">
              </chart>
              <chart v-else :item="item.dataList[1].refData" :type="item.dataList[1].refData.refType" isIndex="index" :id="item.dataList[1].refId"> </chart>
            </div>

            <div v-if="flg" ref="title1" class="item3-2" :style="fontSize1">{{ item.name }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { enChart } from "@/api/businessChart";
import chart from "@/views/businessChart/componentsFull/chart.vue";
import indexFull from "@/mixins/indexFull/indexFull"; // 公共方法
// import toggleFullScreen from "@/mixins/toggleFullScreen"; // 公共方法
import url1 from "../../assets/images/1.png";
import url2 from "../../assets/images/2.png";
import url3 from "../../assets/images/3.png";
import url4 from "../../assets/images/4.png";
import url5 from "../../assets/images/5.png";
import url6 from "../../assets/images/6.png";
import url7 from "../../assets/images/7.png";
import url8 from "../../assets/images/8.png";
import url from "../../assets/images/indexVice1.png";
// import chartFull from "@/views/businessChart/chartFull.vue";

export default {
  name: "indexFull",
  // mixins: [toggleFullScreen],
  mixins: [indexFull],
  data() {
    return {
      flg: false,
      userInfo: {},
      tenantName: "",
      // 原菜单数据
      menuList: [],
      menuList2: [],
      menuList3: [],
      fontSize: "",
      fontSize1: "",
      w1: "",
      url,
      map: {
        员工管理: url1,
        财务管理: url2,
        客商管理: url3,
        采购管理: url4,
        销售管理: url5,
        库存管理: url6,
        资产管理: url7,
        智能驾驶舱: url8
      }
    };
  },
  components: {
    chart
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
    this.initWebscok();
    this.tenantName = this.userInfo.tenantName;
    // this.tenantName = "小微企业数字化自助平台";
    // 小微企业数字化自助平台
    this.menuList = cloneDeep(this.$store.getters["main/menuList"]);
    this.makeMenuList();
    this.menuList2.forEach((item) => {
      item.src = this.map[item.name];
    });
    // console.log(99999999999, this.menuList2);
    // this.addFullScreenListener(this.finish, this.closeFull);10
    // this.toggleFullScreen();
    this.$nextTick(() => {
      this.fontSizefun();
      this.widthfun();
    });
    setTimeout(() => {
      this.flg = true;
      this.$nextTick(() => {
        this.fontSizefun1();
      });
    }, 500);
  },
  methods: {
    className(index) {
      let name = "";
      if (index < 4) {
        //
      } else if (index === 4 || index === 5) {
        name = "hidden-md-and-down";
      } else {
        name = "hidden-lg-and-down";
      }
      return name;
    },
    widthfun() {
      const h = this.$refs.title.clientHeight;

      let w1 = h * 0.55 * (this.tenantName.length || 1) + 10;
      w1 = Math.ceil(w1);
      this.w1 = `min-width: ${w1}px;`;
    },
    fontSizefun() {
      const h = this.$refs.title.clientHeight;
      const h1 = h * 0.55;
      this.fontSize = `font-size: ${h1}px;`;
    },
    fontSizefun1() {
      const h = this.$refs.title1[0].clientHeight;
      const h1 = h * 0.8;
      this.fontSize1 = `font-size: ${h1}px;`;
    },
    // 处理菜单数据 取出所有带有看板数据 业务建模
    makeMenuList() {
      (this.menuList || []).forEach((item) => {
        if (item.type === 3 && item.refData && item.refData.hasBoard === "0" && item.isShow === 0) {
          //  业务建模 分类 并且有 看板
          item.classFlag = true;
          if (this.menuList2.length < 8) {
            this.menuList2.push(item);
          }
        }
        if (item.type === 3 && item.refData && item.dataList.length) {
          //  业务建模
          (item.dataList || []).forEach((item2) => {
            item2.classFlag = false;
            if (item2.refData && item2.refData.hasBoard === "0" && item2.isShow === 0 && item2.dataType !== 10 && item2.dataList && item2.dataList.length) {
              if (this.menuList2.length < 8) {
                this.menuList2.push(item2);
              }
            }
          });
        }
      });
    },
    clickItem(item) {
      // 必须是有看板的数据 才能穿透
      if (item.refData && item.refData.hasBoard === "0") {
        const p1 = {
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
          path: `/toModelChartUserFull?templateId=${item.refData.id}&type=${item.classFlag && item.refData && item.refData.hasBoard === "0" ? "1" : "2"}&templateName=${item.name}`
        };
        const p = {
          type: "sameScreenAuth",
          value: JSON.stringify(p1)
        };
        enChart.sendWebSocketMessage(p);
        if (item.classFlag && item.refData && item.refData.hasBoard === "0") {
          this.$router.push({ path: "/toModelChartUserFull", query: { templateId: item.refData.id, type: 1, templateName: item.name } });
        } else if (item.refData && item.refData.hasBoard === "0") {
          this.$router.push({ path: "/toModelChartUserFull", query: { templateId: item.refData.id, type: 2, templateName: item.name } });
        }
      }
    },
    /**
     * @description: 全屏开启完成事件
     */
    finish() {
      this.$emit("finish");
    },
    // 关闭事件 需要父级处理 这里只会触发
    closeFull() {
      // 返回首页
      this.$router.push({ path: "/home/index" });
    }
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: #3e61d7;
}
.title {
  width: 100%;
  height: 13%;
  font-weight: 600;
  // fulltitlebg
  background: url("../../assets/images/fulltitlebg.jpg") no-repeat 100% 100%;
  background-size: 100% 100%;
  color: #ffffff;
  text-align: center;
  font-size: 260%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  // &::after{
  //   clear: both;
  // }
  div {
    height: 100%;
  }
  .title-1 {
    background: url("../../assets/images/title1.png") no-repeat 100% 100%;
    background-size: 100% 100%;
    flex: 1;
    // float:left
  }
  .title-2 {
    background: url("../../assets/images/title2.png") no-repeat 100% 100%;
    background-size: 101% 100%;
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // float:left
  }
  .title-3 {
    background: url("../../assets/images/title3.png") no-repeat 100% 100%;
    background-size: 101% 100%;
    flex: 1;
    // float:left
  }
}
.conter {
  width: 100%;
  height: 87%;
  background: url("../../assets/images/fullBackground1.jpg") no-repeat 100% 100%;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.item3 {
  // min-width: 50%;
  // max-width: 25%;
  // min-height: 46%;
  // max-height: 46%;
  height: 50%;
  flex: 1;
  // padding: 1%;
}
.grid-content {
  position: relative;
  span {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0;
    text-align: center;
    color: #ffffff;
    height: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
}
.item3-3 {
  border-top: 20px solid rgba(36, 39, 62, 0.6);
  border-bottom: 20px solid rgba(36, 39, 62, 0.6);
  border-left: 20px solid rgba(36, 39, 62, 0.6);
  border-right: 20px solid rgba(36, 39, 62, 0.6);
  -webkit-border-image: url("../../assets/images/border-bg1.png") 48 round; /* Safari 3.1-5 */
  -o-border-image: url("../../assets/images/border-bg1.png") 48 round; /* Opera 11-12.1 */
  border-image: url("../../assets/images/border-bg1.png") 48 round;
}
.item3-1 {
  width: 100%;
  height: 100%;
  position: relative;
  span {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0;
    text-align: center;
    color: #ffffff;
    height: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.item3-2 {
  color: #ffffff;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 30px;
}
// .item3 {
//   width: 1538px;
//   height: 876px;
//   background: #cccccc;
//   margin-left: 152px;
//   margin-bottom: 134px;
//   display: inline-block;
//   position: relative;
//   span {
//     font-size: 48px;
//     color: #ffffff;
//     position: absolute;
//     top: 5px;
//     width: 100%;
//     text-align: center;
//   }
// }
@media only screen and (max-width: 767px) {
  .hidden-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm-only {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .hidden-md-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1199px) {
  .hidden-md-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1200px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .hidden-lg-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1919px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1920px) {
  .hidden-xl-only {
    display: none !important;
  }
}
</style>
