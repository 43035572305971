<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 18:11:55
 * @LastEditTime: 2022-05-25 11:49:51
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 表格指标卡
 * @FilePath: \user\src\views\businessChart\components\cardTable.vue
-->
<template>
  <div class="card-table">
    <table v-if="finish" border="0" cellspacing="33" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px', 'text-align': align[item.align] }">
      <tr v-for="(item1, index) in tableData" :key="index">
        <td class="card-table-td" v-for="(item2, index2) in item1" :key="index2">
          <div
            v-if="item2.iconSet && (item2.iconSet.align === 2 || item2.iconSet.align === 4)"
            :class="item2.iconSet.align === 3 || item2.iconSet.align === 4 ? 'inline' : 'block'"
            :style="{ color: item2.contentColor, 'font-size': item2.contentFont + 'px', 'text-align': align[item.align] }"
            @click.stop="through(item2)"
          >
            {{ item2.value }}
          </div>
          <div v-if="item2.iconSet && item2.iconSet.icon" :class="item2.iconSet.align === 3 || item2.iconSet.align === 4 ? 'inline' : 'block'">
            <en-icon
              :name="'bus-board-icon' + item2.iconSet.icon"
              :size="item2.iconSet.size || '32'"
              :style="{ background: item2.iconSet.color, color: '#fff', 'margin-left': item2.iconSet.align === 4 ? '5px' : '0' }"
              style="border-radius: 50%;padding: 5px;"
            >
            </en-icon>
          </div>
          <div
            v-if="item2.iconSet && (item2.iconSet.align == 1 || item2.iconSet.align === 3)"
            :class="item2.iconSet.align === 3 || item2.iconSet.align === 4 ? 'inline' : 'block'"
            class="col-tits"
            :style="{ color: item2.contentColor, 'font-size': item2.contentFont + 'px', 'text-align': align[item.align], 'margin-left': item2.iconSet.align === 3 ? '5px' : '0' }"
            @click.stop="through(item2)"
          >
            {{ item2.value }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardTable",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      line() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      line() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      line() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      line() {
        return false;
      }
    }
  },
  data() {
    return {
      tableData: [],
      isFull: this.isFullScreen,
      zbData: [],
      align: { 1: "left", 2: "center", 3: "right" },
      finish: false
    };
  },
  mounted() {
    // 复制数据
    this.tableData = JSON.parse(JSON.stringify(this.item.rows));
    console.log("rows", this.item.rows);
    console.log("tableData", this.tableData);
    this.zbData.push(...this.picData.zbData);
    this.setValue();
    this.finish = true;
  },
  methods: {
    // 处理表格数据
    setValue() {
      (this.tableData || []).forEach((element) => {
        element.forEach((element2) => {
          if (JSON.stringify(element2) === "{}") {
            element2.value = "";
            this.$set(element2, "value", "");
          } else {
            this.$set(element2, "value", this.formatValue(element2));
          }
          if (!element2.iconSet) {
            const obj = {
              align: 1,
              color: "#ffcc59",
              icon: ""
            };
            this.$set(element2, "iconSet", obj);
          } else {
            element2.iconSet.align = Number(element2.iconSet.align);
          }
        });
      });
    },
    // 处理显示格式
    formatValue(item) {
      let value = 0;
      (this.zbData || []).forEach((element) => {
        if (item.controlNameVariable === element.id) {
          value = element.value;
        }
      });
      const decimal = Number(item.decimal || 0);
      const showType = String(item.showType);
      const thousandMark = String(item.thousandMark);
      if (String(item.controlType) === "5") {
        // 文本不处理
      } else if (showType === "2") {
        // 百分百
        value = `${(Number(value) * 100).toFixed(decimal)}%`;
      } else if (thousandMark === "1") {
        // 数值
        value = Number(value).toFixed(decimal);
      } else {
        // 千分符
        value = thousandFormat(Number(value || 0).toFixed(decimal));
      }
      return value;
    },
    // 穿透 item 点击项
    through(item) {
      console.log(item);
      if (this.isFullScreen) {
        return;
      }
      if (String(item.controlType) === "5" || String(item.controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-table {
  height: 100%;
  width: 100%;
  padding: 10px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  table {
    margin: 0 auto;
  }
  .card-table-td {
    background: rgba(101, 135, 197, 0.2);
    min-width: 178px;
    height: 70px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
  }
}
.inline {
  display: inline-block;
  vertical-align: middle;
}
.block {
  display: block;
}
*{
  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 8px;
    background: rgba(36, 39, 62, 0.3);;
}
}
</style>
