var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-box" },
    [
      _c("div", { staticClass: "chart-title" }, [
        _c(
          "div",
          { staticClass: "pull-left" },
          [
            _c("en-icon", {
              staticClass: "back-icon",
              attrs: {
                name: "iconfanhuishangcengji",
                size: "18px",
                color: "#A9B5C6",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleBackIconClick.apply(null, arguments)
                },
              },
            }),
            _c(
              "div",
              { staticClass: "header-icon" },
              [
                _c("en-icon", {
                  attrs: {
                    name: "iconmoban-yewujianmo1",
                    size: "16",
                    color: "#FCFCFC",
                  },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "title-text" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
            _vm.type !== "3"
              ? _c(
                  "div",
                  { staticStyle: { cursor: "pointer" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: { command: _vm.picfullChange },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-more el-icon--right",
                          staticStyle: {
                            color: "#A9B5C6",
                            transform: "rotate(90deg)",
                          },
                        }),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "reView",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _c("el-dropdown-item", { attrs: { command: 1 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "putongbak" },
                                    [
                                      _c("en-icon", {
                                        staticStyle: { color: "#3E90FE" },
                                        attrs: {
                                          name: "iconpingpushitu",
                                          size: "14px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" 列表视图 "),
                                  !_vm.type && "2" !== _vm.picType
                                    ? _c("en-icon", {
                                        staticStyle: {
                                          color: "#3E90FE",
                                          "margin-left": "10px",
                                        },
                                        attrs: {
                                          name: "iconyixuan",
                                          size: "14px",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("el-dropdown-item", { attrs: { command: 2 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "putongbak" },
                                    [
                                      _c("en-icon", {
                                        staticStyle: { color: "#A871F6" },
                                        attrs: {
                                          name: "iconkanbanshitu",
                                          size: "14px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" 看板视图 "),
                                  "2" === _vm.picType
                                    ? _c("en-icon", {
                                        staticStyle: {
                                          color: "#3E90FE",
                                          "margin-left": "10px",
                                        },
                                        attrs: {
                                          name: "iconyixuan",
                                          size: "14px",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm.viewType === "3" || _vm.viewType === "5"
                              ? _c(
                                  "el-dropdown-item",
                                  { attrs: { command: 4 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "putongbak" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "iconfont",
                                                staticStyle: {
                                                  color: "#FFA32B",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [_vm._v("")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" 日历视图 "),
                                        "4" === _vm.picType
                                          ? _c("en-icon", {
                                              staticStyle: {
                                                color: "#3E90FE",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                name: "iconyixuan",
                                                size: "14px",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.viewType === "3" || _vm.viewType === "5"
                              ? _c(
                                  "el-dropdown-item",
                                  { attrs: { command: 5 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "putongbak" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "iconfont",
                                                staticStyle: {
                                                  color: "#6B5CE8",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [_vm._v("")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" 甘特图 "),
                                        "5" === _vm.picType
                                          ? _c("en-icon", {
                                              staticStyle: {
                                                color: "#3E90FE",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                name: "iconyixuan",
                                                size: "14px",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.viewType === "5"
                              ? _c(
                                  "el-dropdown-item",
                                  { attrs: { command: 8 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "putongbak" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "iconfont",
                                                staticStyle: {
                                                  color: "#3E90FE",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [_vm._v("")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" 资源视图 "),
                                        "8" === _vm.picType
                                          ? _c("en-icon", {
                                              staticStyle: {
                                                color: "#3E90FE",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                name: "iconyixuan",
                                                size: "14px",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("themeViewComm", {
                  attrs: {
                    templateId: _vm.templateId,
                    viewId: _vm.viewId,
                    templateName: _vm.templateName,
                  },
                }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _vm.condition.length > 0
              ? _c(
                  "en-image-setting",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { name: "shaixuan", size: "small", fill: "#9367EB" },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toShow("popBoardFilter")
                      },
                    },
                  },
                  [_vm._v(" 筛选 ")]
                )
              : _vm._e(),
            _vm.hideList.length > 0
              ? _c(
                  "en-image-setting",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      name: "yincang-kanban",
                      size: "small",
                      fill: "#3e90fe",
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.dialogShow = true
                      },
                    },
                  },
                  [_vm._v(" 显示 ")]
                )
              : _vm._e(),
            _c(
              "en-image-setting",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  name: "kanban-huifumoren",
                  size: "small",
                  fill: "#3e90fe",
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.recovery.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" 恢复排序 ")]
            ),
            _vm.type === "1"
              ? _c(
                  "el-popover",
                  {
                    staticClass: "popover-wrap",
                    attrs: {
                      "popper-class": "popover-btn-more",
                      placement: "bottom",
                      width: "400",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mod-list" },
                      _vm._l(
                        _vm.classifyObj.templateList || [],
                        function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "mod",
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "wrap-icon" },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: _vm.getIcon(item.icon).icon,
                                      size: "18",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "wrap-title" }, [
                                _c("span", { attrs: { title: item.name } }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "reference" }, slot: "reference" },
                      [
                        _c(
                          "en-image-setting",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              name: "gengduo",
                              size: "small",
                              fill: "#3e90fe",
                            },
                          },
                          [_vm._v(" 更多 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "en-image-setting",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { name: "quanping", size: "small", fill: "#3e90fe" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.moreFull.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" 全屏 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { ref: "chartCenter", staticClass: "chart-center" },
        [
          _vm.showList.length === 0
            ? _c("en-result", {
                staticStyle: {
                  background: "#FFF",
                  margin: "10px",
                  "border-radius": "5px",
                },
                attrs: { type: "NoData" },
              })
            : _c(
                "div",
                { staticClass: "chart-con" },
                [
                  _vm.showList.length > 0 && _vm.hackReset1
                    ? _c(
                        "grid-layout",
                        {
                          staticClass: "grid-box",
                          staticStyle: { transition: "none" },
                          attrs: {
                            layout: _vm.showList,
                            "col-num": 12,
                            "row-height": 60,
                            "is-draggable": true,
                            "is-resizable": true,
                            "vertical-compact": false,
                            margin: [0, 8],
                            "use-css-transforms": false,
                          },
                          on: {
                            "update:layout": function ($event) {
                              _vm.showList = $event
                            },
                          },
                        },
                        _vm._l(_vm.showList, function (item, index) {
                          return _c(
                            "grid-item",
                            {
                              key: item.id,
                              staticClass: "grid-item",
                              attrs: {
                                x: index % 2 === 0 ? item.x : item.x + 0.05,
                                y: item.y,
                                w: item.w - 0.05,
                                h: item.h,
                                i: item.i,
                                minW: 2,
                                minH: 2,
                              },
                              on: {
                                resized: _vm.resizedEvent,
                                moved: _vm.movedEvent,
                              },
                            },
                            [
                              _c("chart", {
                                attrs: {
                                  item: item,
                                  id: _vm.id,
                                  name: _vm.name,
                                  type: _vm.type,
                                  resized: _vm.resized,
                                  flag: item.flag,
                                  variables: _vm.variables,
                                  classifyObj: _vm.classifyObj,
                                  isFullScreen: false,
                                  viewsList: _vm.viewsList,
                                },
                                on: {
                                  toParent: _vm.getData,
                                  isHide: _vm.hide,
                                  isFull: _vm.singleFull,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _vm.variablesFlg
        ? _c("boardFilter", {
            ref: "popBoardFilter",
            attrs: { conditions: _vm.condition, variables: _vm.variables },
            on: { saveFilter: _vm.getCon },
          })
        : _vm._e(),
      _c("fullScreen", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isFullScreen,
            expression: "isFullScreen",
          },
        ],
        on: { close: _vm.closeFull, finish: _vm.finishFull },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _vm.finish && _vm.fullType === 1
                  ? _c("div", { staticClass: "full-box" }, [
                      _c("div", { staticClass: "full-title" }, [
                        _vm._v(_vm._s(_vm.singleData.name || "")),
                      ]),
                      _c("div", { staticClass: "full-center" }, [
                        _c(
                          "div",
                          { staticClass: "full-chart" },
                          [
                            _vm.hackReset
                              ? _c("chart", {
                                  attrs: {
                                    item: _vm.singleData,
                                    id: _vm.id,
                                    type: _vm.type,
                                    classifyObj: _vm.classifyObj,
                                    isFullScreen: true,
                                    fullType: _vm.fullType,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "full-btn" }, [
                        _c(
                          "span",
                          { attrs: { title: "上一页" } },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#fff" },
                              attrs: { size: "small", name: "fanhui" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.pre("single")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.play
                          ? _c(
                              "span",
                              { attrs: { title: "播放" } },
                              [
                                _c("en-icon", {
                                  staticStyle: {
                                    color: "#fff",
                                    margin: "0 20px",
                                  },
                                  attrs: {
                                    size: "small",
                                    name: "zanting-kanban",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.startTimer("single")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              { attrs: { title: "暂停" } },
                              [
                                _c("en-icon", {
                                  staticStyle: {
                                    color: "#fff",
                                    margin: "0 20px",
                                  },
                                  attrs: {
                                    size: "small",
                                    name: "bofang-kanban",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.closeTimer("single")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "span",
                          { attrs: { title: "下一页" } },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#fff" },
                              attrs: { size: "small", name: "shouqi" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.next("single")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.finish && _vm.fullType === 2
                  ? _c("div", { staticClass: "full-box" }, [
                      _c("div", { staticClass: "full-title" }, [
                        _vm._v(_vm._s(_vm.picFullSet.name || _vm.name || "")),
                      ]),
                      _c("div", { staticClass: "full-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "full-chart",
                            staticStyle: { padding: "0" },
                          },
                          [
                            _vm.full[_vm.fullI].length > 0 && _vm.hackReset
                              ? _c(
                                  "grid-layout",
                                  {
                                    staticClass: "grid-box",
                                    staticStyle: { transition: "none" },
                                    attrs: {
                                      layout: _vm.full[_vm.fullI],
                                      "col-num": 12,
                                      "row-height": _vm.fullH,
                                      "is-draggable": false,
                                      "is-resizable": false,
                                      "vertical-compact": false,
                                      margin: [8, 8],
                                      "use-css-transforms": false,
                                    },
                                    on: {
                                      "update:layout": function ($event) {
                                        return _vm.$set(
                                          _vm.full,
                                          _vm.fullI,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.full[_vm.fullI], function (item) {
                                    return _c(
                                      "grid-item",
                                      {
                                        key: item.id,
                                        staticClass: "grid-item",
                                        attrs: {
                                          x: item.x,
                                          y: item.y,
                                          w: item.w,
                                          h: item.h,
                                          i: item.i,
                                          minW: 2,
                                          minH: 2,
                                        },
                                      },
                                      [
                                        _vm.finishFullMore
                                          ? _c("chart", {
                                              attrs: {
                                                item: item,
                                                id: _vm.id,
                                                type: _vm.type,
                                                classifyObj: _vm.classifyObj,
                                                isFullScreen: true,
                                                fullType: _vm.fullType,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "full-btn" }, [
                        _c(
                          "span",
                          { attrs: { title: "上一页" } },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#fff" },
                              attrs: { size: "small", name: "fanhui" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.pre.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.play
                          ? _c(
                              "span",
                              { attrs: { title: "播放" } },
                              [
                                _c("en-icon", {
                                  staticStyle: {
                                    color: "#fff",
                                    margin: "0 20px",
                                  },
                                  attrs: {
                                    size: "small",
                                    name: "zanting-kanban",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.startTimer.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              { attrs: { title: "暂停" } },
                              [
                                _c("en-icon", {
                                  staticStyle: {
                                    color: "#fff",
                                    margin: "0 20px",
                                  },
                                  attrs: {
                                    size: "small",
                                    name: "bofang-kanban",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.closeTimer.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "span",
                          { attrs: { title: "下一页" } },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#fff" },
                              attrs: { size: "small", name: "shouqi" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.next.apply(null, arguments)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.dialogShow
        ? _c("hiddenChart", {
            attrs: { show: _vm.dialogShow, hideList: _vm.hideList },
            on: { toShow: _vm.hiddenChartClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }