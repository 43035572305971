<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 10:05:51
 * @LastEditTime: 2020-07-31 18:07:18
 * @LastEditors: zhangjiaheng
 * @Description: 默认指标卡
 * @FilePath: \user\src\views\businessChart\components\cardDefault.vue
-->
<template>
  <div class="card-default">
    <div class="default-conter">
      <p class="default-conter-title" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">{{ item.title }}</p>
      <p class="default-conter-text" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ value }}</p>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardDefault",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 全局变量
    variables: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: "",
      zbData: []
    };
  },
  mounted() {
    console.log(this.item);
    console.log(this.picData);
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
    } else if (showType === "2") {
      // 百分百
      this.value = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.value = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.value = thousandFormat(Number(this.value || 0).toFixed(decimal));
    }
  },
  watch: {
    //
    resized() {},
    isFullScreen() {}
  },
  methods: {
    // 穿透事件
    through() {
      // // 全屏无事件
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-default {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .default-conter {
    text-align: center;
    overflow: hidden;
    p {
      margin: 0;
    }
    .default-conter-title {
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    .default-conter-text {
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
  }
}
</style>
