var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-default" }, [
    _c("div", { staticClass: "default-conter" }, [
      _c(
        "p",
        {
          staticClass: "default-conter-title",
          style: {
            color: _vm.item.titleColor,
            "font-size": _vm.item.titleFont + "px",
          },
        },
        [_vm._v(_vm._s(_vm.item.title))]
      ),
      _c(
        "p",
        {
          staticClass: "default-conter-text",
          style: {
            color: _vm.item.contentColor,
            "font-size": _vm.item.contentFont + "px",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.through.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.value))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }