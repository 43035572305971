<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-29 16:52:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-01 11:53:12
 * @Description: 已隐藏的看板
 * @FilePath: \user\src\views\businessChart\components\hiddenChart.vue
-->
<template>
  <div>
    <en-dialog :visible="visiable" title="已隐藏看板" width="75%" en-body-scroll :close-on-click-modal="false" @close="closeDialog" style="text-aglin:left;">
      <div class="box" v-if="finish">
        <div v-for="(item, index) in list" :key="index" class="temp">
          <div class="temp-content">
            <el-checkbox v-model="item.checked">
              <en-card
                style="margin-left:12px;"
                type="normal"
                :title="item.name"
                :icon-name="typeList[item.type].icon"
                :icon-bg="iconColor(index)"
                icon-color="#FFFFFF"
                :sub-title="typeList[item.type].name"
              >
              </en-card>
            </el-checkbox>
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="visiable = false">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
export default {
  name: "HiddenChart",
  props: {
    // 隐藏的数据
    hideList: {
      type: Array,
      default() {
        return [];
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      list: [],
      finish: false,
      iconList: [],
      typeList: {
        "001": { name: "簇状柱形图", icon: "cuzhuangzhuzhuangtu_2" },
        "002": { name: "簇状条形图", icon: "cuzhuangtiaoxingtu_2" },
        "003": { name: "折线图", icon: "quxiantu_2" },
        "004": { name: "饼图", icon: "bingtu_2" },
        "005": { name: "", icon: "" },
        "006": { name: "自定图", icon: "zidingyitubiao_2" },
        "007": { name: "双轴图", icon: "shuangzhoutu_2" },
        "008": { name: "玫瑰图", icon: "meiguitu_2" },
        "009": { name: "环状图", icon: "huanxingtu_2" },
        "010": { name: "堆积柱状图", icon: "duijizhuzhuangtu_2" },
        "011": { name: "排行榜图", icon: "paihangbang_2" },
        "012": { name: "面积图", icon: "mianjitu_2" },
        "013": { name: "自定义指标卡", icon: "zhibiaoka_2" },
        "014": { name: "百分比柱状堆积图", icon: "baifenbiduijizhu_2" },
        "015": { name: "堆积条形图", icon: "duijitiaoxingtu_2" },
        "016": { name: "百分比堆积条形图", icon: "baifenbaiduijitiaoxingtu_2" },
        "017": { name: "", icon: "" },
        "018": { name: "", icon: "" },
        "019": { name: "行政地图", icon: "baifenbaiduijitiaoxingtu_2" }
      }
    };
  },
  mounted() {
    this.visiable = this.show;
    this.list = this.hideList;
    this.list.forEach((element) => {
      this.$set(element, "checked", false);
      element.checked = false;
    });
    // 数据处理完成 渲染组件
    this.finish = true;
  },
  methods: {
    // 图标背景颜色
    iconColor(index) {
      const obj = {
        0: "#3e90fe",
        1: "#26C393",
        2: "#FFAD2C",
        3: "#FF7E66"
      };
      const a = index % 4;
      return obj[a];
    },
    // 关闭回调
    closeDialog() {
      const list = [];
      this.list.forEach((element) => {
        if (element.checked) {
          list.push(element);
        }
      });
      this.$emit("toShow", list);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  min-height: 115px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .temp {
    display: inline-block;
    margin-right: 16px;
    margin-top: 16px;
    .temp-content {
      display: flex;
      align-items: center;
    }
  }
}
</style>
